import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Footer from '../Footer';
import Auth from '../../helpers/Auth';
import PreLoader from '../PreLoader';
import Loader from '../loader/Loader1';

export default function ChallengesJoin() {
    const [challenge, setChallenge] = useState();
    const [state, setState] = useState({
        is_join: false,
    });
    const { http, user } = Auth();
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        http.get(`/challenges-list/${user.id}`).then((res) => {
            // console.log(res.data.data)
            setChallenge(res.data.data.challenges[0])
        }).catch((err) => {
            console.log(err.response)
        })
    }, []);
    const joinNow = () => {
        setState({ ...state, is_join: true })
        http.post(`/user-join-challenges/${user.id}/${params.challenge_id}`).then((res) => {
            setState({ ...state, is_join: false })
            if (res.data.data.length > 0) {
                if (res.data.data[0].is_joined) {
                    navigate('/challenges')
                }
            } else {
                if (res.data.data.is_joined) {
                    navigate('/challenges')
                }
            }

        }).catch((err) => {
            console.log(err.response);
        })
    }
    return (
        <>
            {
                challenge ? (
                    <>
                        <div className="days28_ai_challenge_main">
                            <div className="header all_chatheader">
                                <i className="fa fa-angle-left" aria-hidden="true" onClick={() => navigate('/challenges')}></i>
                                <h2>{challenge.name}</h2>
                                <div></div>
                            </div>

                            <div className="days28_ai_challenge_img">
                                <div className="container">
                                    <img src={challenge.image_v2} alt="img" />
                                </div>
                            </div>
                            <div className="container">
                                <div className="days28_ai_challenge_btn_div">

                                    <h3>{challenge.name}</h3>
                                    <p>{challenge.description}</p>
                                    <button type='button' className="btn" onClick={joinNow}>Join now</button>
                                </div>
                                <div className="days28_ai_challenge_challenge_div">
                                    <h4>Challenge details</h4>
                                    <div className="days28_ai_challenge_challenge_inner_main">
                                        <div className="days28_ai_challenge_challenge_inner_sub">
                                            <p className="days28_ai_challenge_light_text">Duration</p>
                                            <h5><i className="fa-regular fa-clock"></i>{challenge.duration}</h5>
                                        </div>
                                        <div className="days28_ai_challenge_challenge_inner_sub">
                                            <p className="days28_ai_challenge_light_text">Level</p>
                                            <h5><img src="images/challenge_signal.png" alt="" />{challenge.level}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="days28_ai_challenge_work">
                                    <h4>How does it work?</h4>
                                    <p>Each day for 28 days, we'll introduce you to a brand-new Al tool. These tools cover everything from chatting with Al to creating stunning designs and beyond. It's like unwrapping a new gift every day, filled with exciting possibilities!</p>
                                </div>
                                <div className="days28_ai_challenge_stories">
                                    <h4>Success Stories</h4>
                                    <div className="stories_box">
                                        <div className="stories_name_header">
                                            <h4>Matthew </h4>
                                            <p><i className="fa fa-check"></i> Verified customer</p>
                                        </div>
                                        <div className="stories_content">
                                            <p>Highly recommend for anyone looking to dive into Al in a fun and accessible way!
                                            </p>
                                            <ul className="stories_rating_list">
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="stories_box">
                                        <div className="stories_name_header">
                                            <h4>John</h4>
                                            <p><i className="fa fa-check"></i> Verified customer</p>
                                        </div>
                                        <div className="stories_content">
                                            <p>The material is easy to understand and apply to real world business situations.
                                            </p>
                                            <ul className="stories_rating_list">
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="stories_box">
                                        <div className="stories_name_header">
                                            <h4>Rachel</h4>
                                            <p><i className="fa fa-check"></i> Verified customer</p>
                                        </div>
                                        <div className="stories_content">
                                            <p>Everything was detailed, explained very well and content was pleasing to the eye.
                                            </p>
                                            <ul className="stories_rating_list">
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                                <li><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <Footer />
                        </div>
                        {
                            state.is_join === true && (
                                <Loader />
                            )
                        }
                    </>
                ) : (
                    <PreLoader />
                )
            }
        </>
    )
}
