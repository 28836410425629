export default function Utils() {
    const DateTimeDisplay = (dateTimeString) => {
        const currentDate = new Date();
        const [date, time] = dateTimeString.split('T');
        const [year, month, day] = date.split('-');
        const [hour, minute] = time.split(':');
        const inputDate = new Date(year, month - 1, day, hour, minute);
        const isSameDay = currentDate.toDateString() === inputDate.toDateString();

        const formatDate = (date) => {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        };

        return isSameDay ? `${hour}:${minute}` : formatDate(inputDate);
    };
  return {
    DateTimeDisplay
  };
}
