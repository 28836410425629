import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../helpers/Auth";
import Loader from "./loader/Loader1";

export default function Forgot() {
  const { http, user } = Auth();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [inputField, setInputField] = useState({
    email: "",
  });
  const [modal, setModal] = useState(false);
  const [getdata, setData] = useState(null);
  const [otpValidate, setOtpValidate] = useState({
    status: false,
    message: "Otp Field Required",
    btnStatus: true,
  });

  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };
  const getVerifyCode = (e) => {
    e.preventDefault();
    const newErrors = validateForm(inputField);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setModal(true);
      http
        .post("/user/forget-password", {
          email: inputField.email,
        })
        .then((res) => {
          // console.log(res.data)
          // console.log(`https://webhooks.successpilot.ai/aweber/send_reset_code?${str}`);
          // https://webhooks.successpilot.ai/aweber/send_reset_code?
          http
            .post(
              `https://webhooks.successpilot.ai/convertkit/send_reset_code.php`,
              {
                email: inputField.email,
                resetCode: res.data.data.verification_code,
              }
            )
            .then((res1) => {
              // console.log('webhook response: ', res1.data)
            })
            .catch((err) => {
              console.log("error1:", err.message);
            });
          setData({
            resetCode: res.data.data.verification_code,
            token: res.data.data.token,
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      setModal(false);
      // console.log(`Form submission failed due to validation errors.`);
    }
  };
  const setVerifyCode = (e) => {
    e.preventDefault();
    console.log(inputField.otp);

    if (inputField.otp && inputField.otp.length === 6) {
      setOtpValidate({
        ...otpValidate,
        btnStatus: false,
      });
      http
        .post("/user/verify-code", {
          token: getdata.token,
          resetCode: inputField.otp,
        })
        .then((res) => {
          setOtpValidate({
            ...otpValidate,
            status: false,
            btnStatus: true,
          });
          // console.log("res:", res.data)
          navigate(`/reset-password/${res.data.data.token}`, {
            state: { token: res.data.data.token },
          });
        })
        .catch((err) => {
          setOtpValidate({
            ...otpValidate,
            status: true,
            message: err.response.data.message,
            btnStatus: true,
          });
          console.log("error2:", err.response.data);
        });
    } else {
      if (inputField.otp === undefined) {
        setOtpValidate({
          ...otpValidate,
          status: true,
          message: "Otp Field Required",
        });
      } else {
        setOtpValidate({
          ...otpValidate,
          status: true,
          message: "Otp must be 6 characters",
        });
      }

      // console.log(`Form submission failed due to validation errors.`);
    }
  };
  const validateForm = (data) => {
    const errors = {};

    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }

    return errors;
  };
  const closeModal = () => {
    setModal(false);
    setOtpValidate({
      ...otpValidate,
      status: false,
    });
  };

  return (
    <>
      <div className="container">
        <div className="login_box">
          <h2>Forgot password?</h2>
          <p className="reset_password">
            Don't worry! We'll send You reset instructions. Please enter the
            email address associated with your account.
          </p>
          <form className="login_form">
            <div className="input_box">
              <label htmlFor="reset_password">Email</label>
              <input
                type="email"
                placeholder="youremail@domain.com"
                name="email"
                id="reset_password"
                value={inputField.email}
                onChange={inputsHandler}
              />
              {errors.email && (
                <p className="error_text">{"*" + errors.email}</p>
              )}
            </div>

            <input
              type="submit"
              value="Send Reset Code"
              className="btn Sign_inbtn"
              onClick={getVerifyCode}
            />
          </form>
          <p className="login_subtext">
            Return to <Link to={"/auth/signin"}>Login</Link>
          </p>
        </div>
      </div>
      {modal === true && (
        <div
          className="email_codCheck_poup"
          style={{ display: modal ? "block" : "none" }}
        >
          {getdata ? (
            <div className="email_codCheck_box">
              <div className="Closeicone_box">
                <i className="fa fa-close" onClick={closeModal}></i>
              </div>

              <h3>Check your mail</h3>
              <p>we've sent a confirmation code</p>
              <div className="code_input_box">
                <form>
                  <div className="input_box" style={{ marginTop: "0px" }}>
                    <input
                      type="number"
                      placeholder="Enter Otp"
                      pattern="\d*"
                      maxlength="1"
                      name="otp"
                      value={inputField.otp}
                      onChange={inputsHandler}
                    />
                    {otpValidate.status === true && (
                      <p className="error_text">{"*" + otpValidate.message}</p>
                    )}
                  </div>
                  <input
                    type="submit"
                    value="Verify"
                    name="create_account"
                    className={
                      otpValidate.btnStatus === true
                        ? "btn Sign_inbtn"
                        : "btn Sign_inbtn btn_disable"
                    }
                    onClick={setVerifyCode}
                  />
                </form>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      )}
    </>
  );
}
