import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, redirect } from 'react-router-dom';
import Footer from '../Footer';
import Auth from '../../helpers/Auth';
import PreLoader from '../PreLoader';
export default function ChallengesTask() {
    const [state, setState] = useState({
        default: false,
        active: true,
        daily_challenges_id: 2,
        index: 1,
        day: 1,



    });
    const unorderListDayRef = useRef(null);
    const bodyDayUlRef = useRef(null);
    const leftOffsets = useRef([]);
    const [dailyChallenge, setDailyChallenge] = useState();
    const params = useParams();
    const { http, user, errorHandle } = Auth();
    const navigate = useNavigate();
    const refs = useRef(null);
    useEffect(() => {
        http.get(`/get-user-progress/${user.id}/${params.challenge_id}`).then((res) => {
            res.data.data.daily_challenges.every(function (el, index) {
                // Do your thing, then:
                if (el.complete_status === null || el.complete_status === 0) {
                    // console.log(el,el.complete_status===null,el.complete_status===0)
                    setState({
                        ...state, daily_challenges_id: el.id, day: el.day
                    })
                    return false;
                }
                return true;
            })
            setDailyChallenge(res.data.data);

        }).catch((err) => {
            errorHandle(err);
            // console.log(err.response)
        });


    }, [])


    function smoothScroll() {
        const listItems = unorderListDayRef.current.querySelectorAll('li');
        const taskItems = bodyDayUlRef.current.querySelectorAll('li');

        listItems.forEach((item, index) => {
            const leftOffset = item.offsetLeft;
            leftOffsets.current[index] = leftOffset;
            item.setAttribute('left-offset', leftOffset);

            item.addEventListener('click', () => {
                unorderListDayRef.current.scrollLeft = leftOffset - 30;

                const targetTask = taskItems[index];
                const taskPosition = targetTask.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({ top: taskPosition - 120, behavior: 'smooth' });
            });
        });

        taskItems.forEach((taskItem, index) => {
            taskItem.addEventListener('click', () => {
                const targetLeftOffset = leftOffsets.current[index];
                unorderListDayRef.current.scrollLeft = targetLeftOffset - 30;

                const taskPosition = taskItem.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({ top: taskPosition - 120, behavior: 'smooth' });
            });
        });
    }


    const selectTaskDay = (daily_challenges_id, li_index, status, day, date) => {
        // console.log(status,day,date,status === null && day>14)
        if (status === null && day > 14) {
            return false;
        } else {
            // console.log('dd')
            setState({
                ...state, daily_challenges_id: daily_challenges_id, index: li_index, day: day,
            })
            smoothScroll();
        }
    }
    const startNow = () => {

        http.post(`/user-start-challenges`, {
            user_id: user.id,
            challenge_id: params.challenge_id,
            daily_challenge_id: state.daily_challenges_id,
        }).then((res) => {
            // console.log(res.data)
        }).catch((err) => {
            console.log(err.response)
        })
        navigate(`/challenges/${params.challenge_id}/${state.daily_challenges_id}`, { state: { day: state.day } });
    }

    return (
        <>
            {
                dailyChallenge ? (
                    <>
                        <div className="header">
                            <div className=" daychlng">
                                <i className="fa fa-angle-left" aria-hidden="true" onClick={() => navigate('/challenges')}></i>
                                <h2 className="contday">{dailyChallenge.challenges[0].name}</h2>
                                <div></div>

                            </div>
                        </div>
                        <div className="container_large">
                            <div className="scndheadday">
                                <h1 className="challenge_head">{dailyChallenge.challenges[0].name}</h1>
                                <p className="challenges_description">{dailyChallenge.challenges[0].description}</p>
                                <div className="prdiv">
                                    <div className="progress_barday">
                                        <div className="progress_lineday" style={{ width: dailyChallenge.complete_challenges_days > 0 ? Math.round(dailyChallenge.complete_challenges_days * 100 / dailyChallenge.total_challenges_days) + '%' : 0 + '%' }}></div>
                                    </div>
                                    <span className="dayppercent">{dailyChallenge.complete_challenges_days > 0 && Math.round(dailyChallenge.complete_challenges_days * 100 / dailyChallenge.total_challenges_days)}</span>
                                </div>

                            </div>
                            <div className="scrollstday">
                                <ul className="unorderlistday" ref={unorderListDayRef}>
                                    {
                                        dailyChallenge.daily_challenges.map((el, i) => {

                                            return (
                                                el.day > 0 && (
                                                    <li key={el.id}>
                                                        <button className="uldaybtn" onClick={() => selectTaskDay(el.id, i, el.complete_status, el.day, el.date)} >
                                                            {
                                                                el.day < 15 ? (
                                                                    <>{
                                                                        el.complete_status === 1 ? (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                <rect width="17" height="17" x=".5" y=".5" fill="#19AA32" rx="8.5"></rect>
                                                                                <rect width="17" height="17" x=".5" y=".5" stroke="#19AA32" rx="8.5"></rect>
                                                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m13.197 5.85-5.775 5.775L4.797 9"></path>
                                                                            </svg>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    state.daily_challenges_id === el.id ? (
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                            <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5"></rect>
                                                                                            <rect width="17" height="17" x=".5" y=".5" stroke="#5653FE" rx="8.5"></rect>
                                                                                            <circle cx="9" cy="9" r="5" fill="#5653FE"></circle>
                                                                                        </svg>
                                                                                    ) : (
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                            <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5"></rect>
                                                                                            <rect width="17" height="17" x=".5" y=".5" stroke="#C4C7CC" rx="8.5"></rect>
                                                                                        </svg>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }

                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {
                                                                            el.complete_status === 1 ? (
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                    <rect width="17" height="17" x=".5" y=".5" fill="#19AA32" rx="8.5"></rect>
                                                                                    <rect width="17" height="17" x=".5" y=".5" stroke="#19AA32" rx="8.5"></rect>
                                                                                    <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m13.197 5.85-5.775 5.775L4.797 9"></path>
                                                                                </svg>
                                                                            ) : el.complete_status === 0 ? (
                                                                                <>
                                                                                    {
                                                                                        state.daily_challenges_id === el.id ? (
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                                <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5"></rect>
                                                                                                <rect width="17" height="17" x=".5" y=".5" stroke="#5653FE" rx="8.5"></rect>
                                                                                                <circle cx="9" cy="9" r="5" fill="#5653FE"></circle>
                                                                                            </svg>
                                                                                        ) : (
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                                <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5"></rect>
                                                                                                <rect width="17" height="17" x=".5" y=".5" stroke="#C4C7CC" rx="8.5"></rect>
                                                                                            </svg>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            ) : (
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                    <rect width="17" height="17" x=".5" y=".5" fill="#C4C7CC" rx="8.5"></rect>
                                                                                    <rect width="17" height="17" x=".5" y=".5" stroke="#C4C7CC" rx="8.5"></rect>
                                                                                    <path fill="#fff" d="M9 5c.824 0 1.5.676 1.5 1.5V8h-3V6.5C7.5 5.676 8.176 5 9 5Zm2.5 3V6.5C11.5 5.124 10.376 4 9 4a2.506 2.506 0 0 0-2.5 2.5V8H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-.5ZM6 9h6v4H6V9Z">
                                                                                    </path>
                                                                                    <path fill="#fff" d="M6 13V9h6v4H6Z"></path>
                                                                                </svg>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }

                                                            <strong>{'D' + el.day}</strong>
                                                        </button>

                                                    </li>)
                                            )
                                        }



                                        )
                                    }

                                </ul>
                                <hr className="challenges_divider" />
                            </div>
                            <section className="daysec">
                                <ul className="bodydayul" ref={bodyDayUlRef}>
                                    {
                                        dailyChallenge.daily_challenges.map((el, i) => (

                                            el.day > 0 && (
                                                <li key={el.id} className="taskliday martopdm" >
                                                    <button className={state.daily_challenges_id === el.id ? "myinnerbtnday active" : "myinnerbtnday"} onClick={() => selectTaskDay(el.id, i, el.complete_status, el.day, el.date)}>
                                                        <span className="innerdayspan">
                                                            <img className="myimgwh" src={el.image} />
                                                            <span className="newinnerday">
                                                                <strong className="dayinner_title">Day {el.day}:</strong>
                                                                <span className="dayinner_description">{el.title}</span>
                                                            </span>

                                                        </span>
                                                        {
                                                            el.day < 15 ? (
                                                                <>{
                                                                    el.complete_status === 1 ? (
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                            <rect width="17" height="17" x=".5" y=".5" fill="#19AA32" rx="8.5"></rect>
                                                                            <rect width="17" height="17" x=".5" y=".5" stroke="#19AA32" rx="8.5"></rect>
                                                                            <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m13.197 5.85-5.775 5.775L4.797 9"></path>
                                                                        </svg>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                state.daily_challenges_id === el.id ? (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                        <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5"></rect>
                                                                                        <rect width="17" height="17" x=".5" y=".5" stroke="#5653FE" rx="8.5"></rect>
                                                                                        <circle cx="9" cy="9" r="5" fill="#5653FE"></circle>
                                                                                    </svg>
                                                                                ) : (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                        <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5"></rect>
                                                                                        <rect width="17" height="17" x=".5" y=".5" stroke="#C4C7CC" rx="8.5"></rect>
                                                                                    </svg>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )

                                                                }

                                                                </>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        el.complete_status === 1 ? (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                <rect width="17" height="17" x=".5" y=".5" fill="#19AA32" rx="8.5"></rect>
                                                                                <rect width="17" height="17" x=".5" y=".5" stroke="#19AA32" rx="8.5"></rect>
                                                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m13.197 5.85-5.775 5.775L4.797 9"></path>
                                                                            </svg>
                                                                        ) : el.complete_status === 0 ? (
                                                                            <>
                                                                                {
                                                                                    state.daily_challenges_id === el.id ? (
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                            <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5"></rect>
                                                                                            <rect width="17" height="17" x=".5" y=".5" stroke="#5653FE" rx="8.5"></rect>
                                                                                            <circle cx="9" cy="9" r="5" fill="#5653FE"></circle>
                                                                                        </svg>
                                                                                    ) : (
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                            <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5"></rect>
                                                                                            <rect width="17" height="17" x=".5" y=".5" stroke="#C4C7CC" rx="8.5"></rect>
                                                                                        </svg>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                                                                                <rect width="17" height="17" x=".5" y=".5" fill="#C4C7CC" rx="8.5"></rect>
                                                                                <rect width="17" height="17" x=".5" y=".5" stroke="#C4C7CC" rx="8.5"></rect>
                                                                                <path fill="#fff" d="M9 5c.824 0 1.5.676 1.5 1.5V8h-3V6.5C7.5 5.676 8.176 5 9 5Zm2.5 3V6.5C11.5 5.124 10.376 4 9 4a2.506 2.506 0 0 0-2.5 2.5V8H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-.5ZM6 9h6v4H6V9Z">
                                                                                </path>
                                                                                <path fill="#fff" d="M6 13V9h6v4H6Z"></path>
                                                                            </svg>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }

                                                    </button>
                                                </li>
                                            )



                                        ))
                                    }




                                </ul>
                            </section>

                            <div className="challenges_daybtn"><button type="button" className="daymybtnch" onClick={startNow}>Start now</button></div>

                        </div>
                        <Footer />
                    </>
                ) : (
                    <PreLoader />
                )
            }
        </>
    )

}
