// import guide from "./images/guide.png";
// import recommended_1 from "./images/recommended_1.png";
// import recommended_2 from "./images/recommended_2.png";
// import recommended_3 from "./images/recommended_3.png";
// import recommended_4 from "./images/recommended_4.png";
// import recommended_5 from "./images/recommended_5.png";
// import recommended_6 from "./images/recommended_6.png";
// import recommended_7 from "./images/recommended_7.png";
// import reco_8 from "./images/reco_8.png";
// import reco_9 from "./images/reco_9.png";
// import reco_10 from "./images/reco_10.png";

// const images = {
//     guide,
//     recommended_1,
//     recommended_2,
//     recommended_3,
//     recommended_4,
//     recommended_5,
//     recommended_6,
//     recommended_7,
//     reco_8,
//     reco_9,
//     reco_10
//   }
// export default images;

function importAll(r) {
    let images = {};
    r.keys().map(item => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('./images', true));
export default images;

