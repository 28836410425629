import React from 'react';
import images from "../Assets/Images";

export default function Logo() {
  return (
    <div className="header">
    <img src={images["logo.png"]} alt="logo" />
  </div>
  )
}
