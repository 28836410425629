import React, { useState } from "react";
import images from "../Assets/Images";
import { Link, useLocation } from "react-router-dom";
import Auth from "../helpers/Auth";
import Footer from "./Footer";

export default function Profile() {
  const { token, logout, user } = Auth();
  const location = useLocation();
  const [currentYear, setCurrentYear] = useState(getDate());
  function getDate() {
    const today = new Date();
    return today.getFullYear();
  }

  function logoutUser() {
    // console.log(token)
    if (token != undefined) {
      logout();
    }
  }
  return (
    <>
      <div className="profile_section">

        <div className="container_large  padding-0">
          <div className="profile_header">

            <div></div>
            <p>Profile</p>
            <Link to={'/profile/settings'} state={{ prevPath: location.pathname }}  >
              <img src={images['settings.svg']} alt="settings" />
            </Link>
          </div>
          <div className="profile_body">

            <div className="profile_name">
              <h3>{user.name}</h3>
              <p>{user.email}</p>
            </div>

            <a href="https://successpilot.ai/support-center.html" target="_blank" className="profile_menu_item">

              <div className="profile_item_icon">
                <img src={images['help_icon.svg']} alt="help" />
                <p>help</p>
              </div>
              <i className="fa fa-angle-right"></i>

            </a>
            <div className="profile_menu_item" style={{ cursor: 'pointer' }} onClick={logoutUser}>

              <div className="profile_item_icon" >
                <img src={images['exit_icon.svg']} alt="help" />
                <p className="danger_text">log out</p>
              </div>


            </div>
            <p className="copy_right_text">©{currentYear} Successpilot. All rights reserved.</p>

          </div>
        </div>

      </div>
      <Footer />
    </>
  );
}
