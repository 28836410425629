import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Auth from "../helpers/Auth";
import PreLoader from "./PreLoader";
import Footer from "./Footer";
import parse from "html-react-parser";

export default function Unit() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const { http, user, errorHandle } = Auth();
  const [isActive, setActive] = useState(true);
  const [openIndex, setOpenIndex] = useState(0);
  const [unitLesson, setUnitLesson] = useState([]);
  const [completeLesson, setCompleteLesson] = useState(0);

  const [stateData, setStateData] = useState({
    active_unit: '',
    active_lesson: ''
  });

  const style = {
    open: {
      minHeight: "370px",
      transition: ".5s ease-in",
    },
    close: {
      maxHeight: "0px",
      transition: ".5s ease-out",
      display: "none",
      // transform: "1s"
    },
  };
  const params = useParams();
  const unitActive = (index) => {
    setActive(!isActive);
    setOpenIndex(index);

    setTimeout(() => {
      endOfListRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  const endOfListRef = useRef(null);
  useEffect(() => {
    if (state && state.activeUnit) {
      // console.log(state.activeUnit)
      setOpenIndex(state.activeUnit);
    }

    fetchUnitLesson(params.guide_id, user);
    fetchUserCompleteLessonProgress(params.guide_id, user);
    lastCompleteLesson(params.guide_id, user);
  }, []);
  // Get ID from URL

  // console.log(params)
  function fetchUnitLesson(guide_id, user) {
    // .get(`/unit-lesson/${state.guide_id}`)
    // .get(`/unit-lesson-complete-user/${guide_id}/${user.id}`)

    http
      .get(`/unit-lesson-complete-user/${guide_id}/${user.id}`)
      .then((res) => {
        // console.log(res.data);
        setUnitLesson(res.data.data);
      })
      .catch((err) => {
        errorHandle(err);
        // console.log(err.response.data);
      });
  }

  function fetchUserCompleteLessonProgress(guide_id, user) {
    http
      .get(`/user-complete-lesson-by-guideID/${guide_id}/${user.id}`)
      .then((res) => {
        // console.log("ff",res.data);
        if (res.data.data) {
          setCompleteLesson(Math.round(res.data.data));
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  function lastCompleteLesson(guide_id, user) {
    http
      .get(`/get-current-complete-lesson/${guide_id}/${user.id}`)
      .then((res) => {
        // console.log(res.data.data[0].coursiv_unit_id)
        if (res.data.status === 1 && res.data.data[0]) {
          //set last unit lesson complete status and update default open unit
          setOpenIndex(res.data.data[0].coursiv_unit_id);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  const startLesson = (unit_id, lesson) => {
    http
      .post("/add-start-lesson-status", {
        user_id: user.id,
        guide_id: params.guide_id,
        unit_id: unit_id,
        lesson_id: lesson.id,
      })
      .then((res) => {
        // console.log(res.data);
      }).catch((err)=>{
        errorHandle(err)
      });
    navigate(`/guides/${params.guide_id}/${unit_id}/${lesson.id}`, {
      state: {
        prevPath: pathname,
        lesson_id: lesson.id,
        unit_id: unit_id,
        order_no: lesson.order_no,
      }
    })
  };

  return (
    <>
      {unitLesson.length > 0 ? (
        <div>
          <div className="step_2">
            <div className="step2_header">
              <div className="left_arrow" onClick={() => navigate("/")}>
                <i className="fa fa-angle-left" aria-hidden="true"></i>
              </div>
              <h4>{unitLesson[0].name}</h4>
            </div>
            <div className="container_large">
              <div class="lesson_header">
                <img src={unitLesson[0].images} alt="guide" />
                <div class="lesson_duration">
                  <ul>
                    <li>{unitLesson[0].coursiv_units.length} Unit</li>
                    <li>
                      <span></span>
                      {unitLesson[0].coursiv_units.length} Hours
                    </li>
                  </ul>
                </div>
                <h3 class="lesson_heading">{unitLesson[0].name}</h3>
                <h5 class="lesson_subHeading">{unitLesson[0].description}</h5>
                <div class="progress_barScore">
                  <div class="progress_bar">
                    <div
                      class="progress_line"
                      style={{ width: completeLesson + "%" }}
                    ></div>
                  </div>
                  <div>
                    <p class="progress-score">{completeLesson}</p>
                  </div>
                </div>
              </div>
              <div className="lesson_container">
                {unitLesson[0].coursiv_units?.map((items, index) => {
                  return (
                    <div
                      key={items.id}
                      className={
                        (isActive && openIndex === items.id) ||
                          (isActive && index === 0 && openIndex === 0)
                          ? "lesson-unit unit__active"
                          : "lesson-unit"
                      }
                      att={index}
                      onClick={() => unitActive(items.id)}
                    >
                      <div className="lesson_unitHeader">
                        <p>{items.name}</p>
                        <div className="lessonOpen_icon_box">
                          <i className="fa fa-angle-up"></i>
                        </div>
                      </div>
                      <div
                        className="unitlesson_box"
                        style={
                          (isActive && openIndex === items.id) ||
                            (isActive && index === 0 && openIndex === 0)
                            ? style.open
                            : style.close
                        }
                      >
                        {items.coursiv_lessons.map((lesson, i) => {
                          return (
                            <>
                              {
                                <label
                                  key={lesson.id}
                                  htmlFor={`finding_${items.id + "_" + lesson.id
                                    }`}
                                  className={
                                    lesson.order_no === 1
                                      ? lesson.user_complete_lessons.length >
                                        0 &&
                                        lesson.user_complete_lessons[0]
                                          .is_lesson_complete
                                        ? "lesson_checkBox complete_lessson"
                                        : "lesson_checkBox"
                                      : lesson.user_complete_lessons.length ===
                                        0
                                        ? "lesson_checkBox  lesson_lock"
                                        : lesson.user_complete_lessons[0]
                                          .is_lesson_complete
                                          ? "lesson_checkBox complete_lessson"
                                          : "lesson_checkBox"
                                  }
                                >
                                  <div className="checkMark_box">
                                    <input
                                      type="radio"
                                      id={`finding_${items.id + "_" + lesson.id
                                        }`}
                                      name={"selectLesson" + items.id}
                                      defaultChecked={
                                        lesson.order_no === 1 ? lesson.user_complete_lessons.length >
                                        0 &&
                                        lesson.user_complete_lessons[0]
                                          .is_lesson_complete===false || lesson.user_complete_lessons.length===0 && 'true':lesson.user_complete_lessons.length >
                                          0 &&
                                          lesson.user_complete_lessons[0]
                                            .is_lesson_complete===false && 'true'

                                      }
                                    />
                                    {lesson.order_no === 1
                                      ? lesson.user_complete_lessons.length >
                                        0 &&
                                        lesson.user_complete_lessons[0]
                                          .is_lesson_complete
                                        ? parse(
                                          '<i className="fa-solid fa-circle-check"></i>'
                                        )
                                        : ""
                                      : lesson.user_complete_lessons.length ===
                                        0
                                        ? parse(
                                          '<i className="fa-solid fa-lock"></i>'
                                        )
                                        : lesson.user_complete_lessons[0]
                                          .is_lesson_complete
                                          ? parse(
                                            '<i className="fa-solid fa-circle-check"></i>'
                                          )
                                          : ""}
                                  </div>
                                  <div className="lesson_imgBox">
                                    <img src={lesson.images} alt="guide" />
                                  </div>
                                  <div className="lesson_check_content">
                                    <h6>{lesson.name}</h6>
                                    <span>lesson</span>
                                  </div>
                                  <button className="btn start_lesson_btn" onClick={() =>
                                    startLesson(items.id, lesson)
                                  }>
                                    Start lesson
                                  </button>

                                </label>
                              }
                            </>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div ref={endOfListRef}></div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <PreLoader />
      )}
    </>
  );
}
