import React, { useState, useEffect } from 'react'

export default function Task({ task, pageType, openPlayParam, setContinueBtn, openPlayParamData }) {
  const [taskContent, setTaskContent] = useState();
  useEffect(() => {
    let task_content = JSON.parse(JSON.parse(task))[0];
    setTaskContent(task_content)
  }, [])
  // console.log('task_content',openPlayParamData)

  const openPlayBtn = () => {
    // console.log(openPlayParamData)
    openPlayParam((prevData) => ({ ...prevData, chatOpen: true, data: taskContent }));
  }

  return (
    <>
      {
        taskContent && (<div className="task_box">

          <p className="guide-lesson-task__title">Task</p>
          <p className="guide-lesson-task__text">{taskContent.description}</p>

          {
            openPlayParamData.task_skip ? (
              <div className="guide-lesson-task__actions">
                <div className="task-skipped"> <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" fill="none">
                  <path fill="#6B7280" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width=".8" d="M5.42 11.413a.583.583 0 0 1 0-.825L8.509 7.5 5.421 4.413a.583.583 0 0 1 .825-.825l3.5 3.5a.583.583 0 0 1 0 .825l-3.5 3.5a.583.583 0 0 1-.825 0Z"></path>
                </svg> <span>Task skipped</span></div>
              </div>
            ) : openPlayParamData.task_complete ? (<div className="guide-lesson-task__actions">
              <div className="task-completed"> <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" fill="none">
                <path stroke="#19AA32" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m11.667 4-6.416 6.417L2.334 7.5"></path>
              </svg> <span>Task completed</span> </div>
            </div>) : (<div className="guide-lesson-task__actions" style={{ position: 'relative' }}>
              <button type="button" className="ui-primary-button" onClick={openPlayBtn} style={{ cursor: 'pointer' }}>Open Playground</button>
            </div>)
          }

          {/* {
            openPlayParamData.task_complete && (
              <div className="guide-lesson-task__actions">
                <div className="task-completed"> <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" fill="none">
                  <path stroke="#19AA32" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m11.667 4-6.416 6.417L2.334 7.5"></path>
                </svg> <span>Task completed</span> </div>
              </div>
            )
          }

          {
            openPlayParamData.task_complete === false || openPlayParamData.task_skip===false && (
              <div className="guide-lesson-task__actions" style={{ position: 'relative' }}>
                <button type="button" className="ui-primary-button" onClick={openPlayBtn} style={{ cursor: 'pointer' }}>Open Playground</button>
              </div>
            )
          } */}


        </div>)
      }
    </>

  )
}
