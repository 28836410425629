import React from 'react';
import images from "../../Assets/Images";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export default function ChallengesComplete() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const closeLesson = () => {
        // console.log(location)
        navigate(`/challenges/${params.challenge_id}/tasks`)
    }
    return (
        <div className='step_5'>
            <div className='container_large'>

                <div className="complete_lessson_box">
                    <button className="page_close" onClick={closeLesson} ><i className="fa fa-close"></i></button>
                    <div>
                        <img src={images["complete_lesson.svg"]} alt="" />
                    </div>
                    <h4>Lesson complete!</h4>
                    <p>Keep up the practice to maintain your progress!</p>
                    <Link to={`/challenges/${params.challenge_id}/tasks`} className="btn start_lesson btn_fix">Continue</Link>
                </div>
            </div>
        </div>
    )
}
