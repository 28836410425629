import axios from "axios";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function AuthUser() {
  const navigate = useNavigate();

  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    return tokenString;
  };

  const getUser = () => {
    // const userString = sessionStorage.getItem("user");
    const userString = localStorage.getItem("user");

    if (userString) {
      // return JSON.parse(atob(userString));
      return JSON.parse(userString);
    }

    return userString;

    // console.log(userString);
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());
  // useEffect(()=>{

  // },[])

  const saveToken = (user, token) => {
    // localStorage.setItem("user", btoa(JSON.stringify(user)));
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);

    setToken(token);
    setUser(user);
    if (user.isplanActive === false) {
      navigate("/profile/subscription-plan");
    } else {
      navigate("/");
    }
  };

  const http = axios.create({
    baseURL: "https://api.successpilot.ai/api/v1",
    headers: {
      "Content-type": "application/json",
      accessToken: `Bearer ${token}`,
    },
  });

  const logout = () => {
    localStorage.clear();
    navigate("/auth/signin");
  };
  const errorHandle = (err) => {
    /**
     * status=3 token expire
     */
    console.log(err.response.data);
    if (err.response.data.status === 0) {
      logout();
    }
    if (err.response.data.status === 3) {
      logout();
    }
  };
  return {
    setToken: saveToken,
    token,
    user,
    getToken,
    http,
    logout,
    errorHandle,
  };
}
