import React, { useState } from "react";
import "./Assets/css/Coursiv.css";
import "./Assets/css/style.css";
import Auth from "./helpers/Auth";
import GuestUser from "./nav/GuestUser";
import AuthUser from "./nav/AuthUser";

function App() {
  const { getToken,user } = Auth();

  // console.log(getToken());
  if (!getToken()) {
    return <GuestUser />;
  }
  return <AuthUser />;
}

export default App;
