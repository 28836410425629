import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Logo from './Logo';
import Auth from "../helpers/Auth";
import Loader from "./loader/Loader1";

export default function ResetPassword() {
    const [inputField, setInputField] = useState({
        password: "",
        cnf_password: ""
    });
    const navigate = useNavigate();
    const { state } = useLocation();
    const { http } = Auth();
    const [passwordView, setPasswordView] = useState(true);
    const [cnfPasswordView, setCnfPasswordView] = useState(true);
    const [errors, setErrors] = useState({});
    const params = useParams();
    const [status, setStatus] = useState({
        status: false,
        popUp: false,
        message: '',
    })


    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    };

    const resetPassword = (e) => {
        e.preventDefault();
        const newErrors = validateForm(inputField);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setStatus({
                ...status,
                status: true,

            })
            http
                .post("/user/reset-password", {
                    password: inputField.password,
                    token: params.token,
                })
                .then((res) => {
                    // console.log("res:", res.data)
                    // alert(res.data.message);

                    setStatus({
                        ...status,
                        status: false,
                        popUp: true,
                        message: res.data.message
                    })

                }).catch((err) => {
                    if (err.response.data.status === 3) {
                        navigate('/auth/forgot')
                    } else {
                        setStatus({
                            ...status,
                            status: false,
                            popUp: true,
                            message: err.response.data.message
                        })
                    }

                    // console.log("error2:", err.response.data)
                })
        } else {
            // console.log(`Form submission failed due to validation errors.`);
        }
    }
    const closeModal = () => {
        navigate('/auth/signin')
    }


    const viewPassword = () => {
        if (passwordView) {
            setPasswordView(false);
        } else {
            setPasswordView(true);
        }
    };
    const viewPasswordCnf = () => {
        if (cnfPasswordView) {
            setCnfPasswordView(false);
        } else {
            setCnfPasswordView(true);
        }
    };
    const validateForm = (data) => {
        const errors = {};
        let label = "";
        if (!data.password) {
            errors.password = "Password is required";
        } else if (data.password.length < 8) {
            errors.password = `Password must be 8 characters.`;
        } else if (!/[!@#$%^&*?_~]{1,}/.test(data.password)) {
            //min 1 special characters
            // console.log(/[!@#$%^&*?_~]{1,}/.test(data.password))
            label = "Special character.";
            errors.password = `Password must be 8 characters with at least one ${label}  `;
        } else if (!/[a-z]{1,}/.test(data.password)) {
            //min 1 a-z
            label = "Small character.";
            errors.password = `Password must be 8 characters with at least one ${label}  `;
        } else if (!/[A-Z]{1,}/.test(data.password)) {
            //min 1 A-Z
            label = "Alphabet character.";
            errors.password = `Password must be 8 characters with at least one ${label}  `;
        } else if (!/[0-9]{1,}/.test(data.password)) {
            //min 1 0-9
            label = "Numeric character.";
            errors.password = `Password must be 8 characters with at least one ${label}  `;
        }

        if (data.cnf_password !== data.password) {
            errors.cnf_password = "Passwords do not match";
        }

        return errors;
    };


    return (

        <>
            <div className="container" style={{ filter: status.popUp === true ? 'blur(1px)' : '' }}>

                <Logo />
                <div className="login_box">
                    <h2>Reset Password</h2>
                    <form className="login_form" onSubmit={resetPassword}>

                        <div className="input_box">
                            <label htmlFor="password">Password</label>
                            <input
                                type={passwordView ? "password" : "text"}
                                placeholder="Password"
                                id="password"
                                name="password"
                                onChange={inputsHandler}
                                value={inputField.password}
                            />
                            {errors.password && (
                                <p className="error_text">{"*" + errors.password}</p>
                            )}
                            <i
                                className={
                                    passwordView ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"
                                }
                                onClick={viewPassword}
                            ></i>
                        </div>
                        <div className="input_box">
                            <label htmlFor="confrim_password">Confirm Password</label>
                            <input
                                type={cnfPasswordView ? "password" : "text"}
                                placeholder="Confirm Password"
                                id="confrim password"
                                name="cnf_password"
                                onChange={inputsHandler}
                                value={inputField.cnf_password}
                            />
                            {errors.cnf_password && (
                                <p className="error_text">{"*" + errors.cnf_password}</p>
                            )}
                            <i
                                className={
                                    cnfPasswordView
                                        ? "fa-solid fa-eye"
                                        : "fa-solid fa-eye-slash"
                                }
                                onClick={viewPasswordCnf}
                            ></i>
                        </div>


                        <input
                            type="submit"
                            value="Reset Password"
                            className="btn Sign_inbtn"
                        // disabled = {!login.is_login}

                        />
                    </form>

                </div>
                {
                    status.status === true && (
                        <Loader />
                    )
                }
            </div>
            {
                status.popUp === true && (
                    <div class="email_codCheck_box">
                        <div class="Closeicone_box">
                            <i class="fa fa-close" onClick={closeModal}></i>
                        </div>

                        {/* <h3>Check your mail</h3> */}
                        <p>{status.message}</p>
                        <div class="code_input_box">
                            <input type="submit" value="Ok" name="create_account" class="btn Sign_inbtn" onClick={closeModal} />
                        </div>
                    </div>
                )
            }

        </>

    )
}
