import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../Logo';
import Footer from '../Footer';
import Auth from '../../helpers/Auth';
import PreLoader from '../PreLoader';
import images from '../../Assets/Images';

export default function Challenges() {
    const [state, setState] = useState({
        home: true,
        news: false
    })
    const [challenge, setChallenge] = useState();
    const { http,user } = Auth();
    const navigate = useNavigate();
    const tabContent = (params) => {
        if (params === 'home') {
            setState({ ...state, home: true, news: false })
        }
        if (params === 'news') {
            setState({ ...state, home: false, news: true })
        }
    }
    useEffect(() => {
        http.get(`/challenges-list/${user.id}`).then((res) => {
            // console.log(res.data.data)
            if (!res.data.data.is_joined) {
                navigate(`/challenges/${res.data.data.challenges[0].id}`)
            }
            setChallenge(res.data.data)
        }).catch((err) => {
            console.log(err.response)
        })
    }, []);
    return (
        <>
            {
                challenge ? (
                    <>
                        <Logo />
                        <div className="container">
                            <button className="tablink" onClick={() => tabContent('home')} style={{ borderBottom: state.home === true ? '1px solid rgb(86, 83, 254)' : '1px solid rgb(221, 221, 221)' }}>All</button>
                            <button className="tablink" onClick={() => tabContent('news')} style={{ borderBottom: state.news === true ? '1px solid rgb(86, 83, 254)' : '1px solid rgb(221, 221, 221)' }}>Completed</button>

                            <div className="tabcontent" style={{ display: state.home === true ? 'block' : 'none' }}>
                                <div className="heading_box">
                                    <h3>My challenges</h3>
                                </div>

                                <button className="first-challenge-box" onClick={() => navigate(`${challenge.challenges[0].id}/tasks`)} style={{ cursor: 'pointer' }}>
                                    <div className="img-box">
                                        <img src={challenge.challenges[0].image} alt="challenge-1" />
                                    </div>
                                    <div className="textbox">
                                        <h5>{challenge.challenges[0].name}</h5>
                                        <p>Your progress: {challenge.complete_challenges_days} of {challenge.total_days} Days</p>
                                        <div className="progress_bar challenge-progress_bar" >
                                            <div className="progress_line challenge-progress" style={{width:Math.round(challenge.complete_challenges_days*100/challenge.total_days)+'%'}}></div>
                                        </div>
                                    </div>

                                </button>

                                <div className="heading_box">
                                    <h3>Challenges</h3>
                                </div>

                                <div className="second-challenge-box" onClick={() => navigate(`${challenge.challenges[0].id}/tasks`)} style={{ cursor: 'pointer' }}>
                                    <img src={challenge.challenges[0].image} alt="challenge-1" />
                                    <h4>{challenge.challenges[0].name}</h4>
                                    <p>
                                        <strong>{challenge.challenges[0].duration}</strong>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" fill="none"><circle cx="2" cy="2" r="2" fill="#6B7280"></circle></svg>
                                        <strong>{challenge.challenges[0].level}</strong>
                                    </p>
                                </div>

                            </div>
                            <div className="tabcontent" style={{ display: state.news === true ? 'block' : 'none' }}>
                                <div className="tab2-box">
                                    <img src={images['complete.svg']} alt="challenge-tab2-img" />
                                    <h3>No completed challenges yet</h3>
                                    <p>Dive into the challenges and unlock your potential</p>
                                </div>

                            </div>



                        </div>
                        <Footer />

                    </>
                ) : (
                    <PreLoader />
                )
            }


        </>
    )
}
