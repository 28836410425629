import React, { useState, useEffect, useRef } from "react";
import Intro from "./LessonIntro";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Auth from "../helpers/Auth";
import PreLoader from "./PreLoader";
import Chunk from "./content/Chunk";
import Task from "./content/Task";
import Quiz from "./content/Quiz";
import OpenChatGpt from "./content/OpenChatGpt";

export default function LessonContent() {
  const [state, setState] = useState(true);
  const [count, setCount] = useState(0);
  const [progress_bar, setProgress] = useState(5);
  const { http, user } = Auth();
  const location = useLocation();
  const navigate = useNavigate();
  const [totalStep, setTotalStep] = useState(0);
  const [continueBtn, setContinueBtn] = useState(true);
  const [openPlay, setOpenPlay] = useState(
    {
      chatOpen: false,
      data: '',
      task_skip: false,
      task: false,
      task_complete:false
    }
  );

  const endOfListRef = useRef(null);
  const [stepData, setStepData] = useState([]);

  const [lessonContent, setLessonContent] = useState([]);
  const [intro, setIntro] = useState([]);
  const params = useParams();
  // console.log(location);
  useEffect(() => {

    http
      .get(`/lesson/page/${params.lesson_id}`)
      .then((res) => {
        // console.log(res.data);
        if (res.data.data.length > 0) {
          res.data.data.forEach(element => {
            // console.log(element.page_type_name);
            if (element.page_type_name === "Intro") {
              setIntro(element);
            }
            if (element.page_type_name === "Step") {
              if (element.coursiv_lesson_page_contents[0].page_type === 'quiz') {
                setContinueBtn(false)
              }
              if (element.coursiv_lesson_page_contents[0].page_type === 'task') {
                setOpenPlay({ ...openPlay, task: true })
              }
              setLessonContent(element.coursiv_lesson_page_contents);
              setTotalStep(element.coursiv_lesson_page_contents.length)
              setStepData([element.coursiv_lesson_page_contents[0]]);
              setProgress(100 / element.coursiv_lesson_page_contents.length);
              // console.log(element, element.coursiv_lesson_page_contents[0])
            }

          });
        }


        // setLessonContent(res.data.data);

      })
      .catch((err) => {
        console.log(err.response, err.message);
      });
  }, []);



  const nextContent = () => {
  
    // Scroll to the bottom after a brief delay to ensure the DOM is updated
    // console.log(count, totalStep)
    // totalStep-1 because first index show default
   
    if (totalStep - 1 > count) {
      // console.log(count)
      setTimeout(() => {
        endOfListRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
      // Finish lesson
      if (lessonContent[count + 1].page_type === 'quiz') {
        setContinueBtn(false)
      }

      if (openPlay.task) {
        // console.log('ff',openPlay)
        setOpenPlay({ ...openPlay, task_skip: true,task:false,task_complete:false })
        // console.log('f2',openPlay)
      }

      if (lessonContent[count + 1].page_type === 'task') {
        setOpenPlay({ ...openPlay, task: true,task_complete:false  })
      }


      setStepData((prevData) => [
        ...prevData,
        lessonContent[count + 1]])

      setProgress((prevData) => prevData + (100 / totalStep));

    } else {
      // location.state.prevPath = location.pathname;
      if (location.state && location.state.order_no) {
        // console.log(location.state.order_no)
        http
          .post("/update-complete-lesson-status", {
            user_id: user.id,
            guide_id: params.guide_id,
            unit_id: params.unit_id,
            lesson_id: params.lesson_id,
            lesson_order_no: location.state.order_no,
          })
          .then((res) => {
            // console.log(res.data)
          }).catch((err) => console.log(err.message))
      }


      navigate(
        `/guides/${params.guide_id}/${params.unit_id}/${params.lesson_id}/complete`,
        { state: location.state }
      );
    }

    setCount(count + 1);

  };

  const closeLesson = () => {
    // console.log(location);
    navigate(`/guides/${params.guide_id}`, { state: location.state });
  };




  return (
    <>
      {lessonContent.length > 0 || intro.length > 0 ? (
        <>
          {state ? (
            <Intro
              setLessonState={{
                setState: setState,
                setCount: setCount,
                intro: intro,
              }}
            />
          ) : openPlay.chatOpen ? <OpenChatGpt openPlayParam={setOpenPlay} taskData={openPlay.data} endOfListRef={endOfListRef} /> : (
            <div className="step_3">
              <div className="container_large">
                <div className="start_lesson_header">
                  <div className="closeLesson_iconBox" onClick={closeLesson}>
                    <i className="fa fa-close"></i>
                  </div>
                  <div className="lessson_progress_bar">
                    <div
                      className="lessson_progress_line"
                      style={{ width: progress_bar + "%" }}
                    ></div>
                  </div>
                </div>
                <div className="start_lesson_contentBox" id="lesson_content">
                  {stepData.length > 0 && stepData.map((item, index) => (

                    <div key={item.id}>
                      {

                        item.page_type === 'chunk' ? (
                          <Chunk chunk={item.content} pageType="step" />
                        ) : item.page_type === 'quiz' ? (
                          <Quiz quiz={item.content} pageType="step" step_count={item.id} setContinueBtn={setContinueBtn} />
                        ) : item.page_type === 'task' ? (
                          <Task task={item.content} pageType="step" openPlayParam={setOpenPlay} setContinueBtn={setContinueBtn} openPlayParamData={openPlay} />

                        ) : ''
                      }
                    </div>
                  ))}
                  <div ref={endOfListRef}></div>
                </div>

                {/* {
                  continueBtn && <button
                    className="btn start_lesson btn_fix"
                    onClick={nextContent}
                    style={openPlay.task_skip?{backgroundColor:'#f5f7f9',color:'#40444d'}:{}}
                  >
                    {openPlay.task_skip?'Skip practice':totalStep - 1 > count ? 'Continue' : 'Finish lesson'}
                  </button>
                } */}
                {
                  continueBtn && (
                    <>
                      {
                        openPlay.task ? (<button
                          className="btn start_lesson btn_fix"
                          onClick={nextContent}
                          style={{ backgroundColor: '#f5f7f9', color: '#40444d', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          <span>Skip practice</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#40444D" d="M9.293 18.707a1 1 0 0 1 0-1.414L14.586 12 9.293 6.707a1 1 0 0 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0Z"></path></svg>
                        </button>) : (
                          <button
                            className="btn start_lesson btn_fix"
                            onClick={nextContent}

                          >
                            {totalStep - 1 > count ? 'Continue' : 'Finish lesson'}
                          </button>
                        )

                      }
                    </>
                  )
                }
              </div>
            </div>
          )}

        </>
      ) : (
        <PreLoader />
      )}
    </>
  );
}
