import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Footer from '../Footer';
import Auth from "../../helpers/Auth";
import PreLoader from '../PreLoader';
import Utils from "../../helpers/Utils";


export default function ChatBotHistory() {
    const [history, setHistory] = useState();
    const { http, user } = Auth();
    const {DateTimeDisplay} = Utils();
    const navigate = useNavigate();
 
    const [state, setState] = useState({
        botPop: false
       

    });
    const [searchId, setSearchId] = useState([]);
    const [categories, setCategories] = useState([]);
    const [bots, setBots] = useState([]);
    const [active, setActive] = useState(0);
    const [inputField, setInputField] = useState({
        key: "",

    });

    useEffect(() => {

        http.get('/list-bot-categories').then((res) => {
            setCategories(res.data.data);
            // console.log(res.data)
        }).catch((err) => {
            console.log(err.response)
        })

        http.get(`/get-bot-history/${user.id}`).then((res) => {
            // console.log(res.data.data)
            setHistory(res.data.data);
        }).catch((err) => {
            console.log(err.response)
        })
        getBots(1);
    }, [])

    const getBots = (id, i) => {
        setActive(i);
        http.get(`/bots-list-categroy-wise/${id}`).then((res) => {
            setBots(res.data.data);
            // console.log(res.data)
        }).catch((err) => {
            console.log(err.response)
        })
    }

    const botPopUp = () => {
        document.body.style.paddingRight = '17px';
        document.body.style.overflow = 'hidden';
        setState({ ...state, botPop: true });
    }
    const closePopUp = () => {
        document.body.style.paddingRight = '';
        document.body.style.overflow = '';
        setState({ ...state, botPop: false })
    }

    const inputsHandler = (e) => {

        const filtered = bots.filter((el) =>
            el.title.toLowerCase().includes(inputField.key)
        );
        setBots(filtered)
        //   console.log(filtered)
        //   const element = document.getElementById(filtered[0].title);
        //   if (element) {
        //     element.scrollIntoView({ behavior: "smooth" });
        //   }
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    };
    

    return (
        <>
            <div class="ai_boats" onTouchStart={()=>setState({...state,botPop:false})}>
                <div class="header all_chatheader">
                    <i class="fa fa-angle-left" aria-hidden="true" onClick={() => navigate('/ai-bots')}></i>
                    <h2>All chats</h2>
                    <svg onClick={botPopUp} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" id="allChat_poup_show">
                        <path fill="#565B66" d="m11 21.013 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414 0-.534-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L11 16.583v4.43ZM22.045 8.458l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58ZM13 17.417l6.03-5.973 1.586 1.586L14.587 19 13 19.007v-1.59Z">
                        </path>
                        <path fill="#565B66" d="M9 25h14c1.103 0 2-.897 2-2v-8.668l-2 2V23H12.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H9V9h6.847l2-2H9c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Z">
                        </path>
                    </svg>

                </div>

                {
                    history ? (
                        <div class="container_large all_chat_list_container">

                            <div class="ai_chat_Search_header">
                                <form>
                                    <label class="ai_search-form">
                                        <input type="text" placeholder="Search chats" name='key' onChange={inputsHandler} value={inputField.key} />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none">
                                            <path fill="#565B66" d="M6.667 3.167a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-5.334 4a5.333 5.333 0 1 1 9.547 3.27l3.591 3.592a.667.667 0 0 1-.942.943L9.937 11.38a5.333 5.333 0 0 1-8.604-4.213Z">
                                            </path>
                                        </svg>
                                    </label>
                                </form>


                            </div>

                            {
                                history.map((el, i) => {
                                    return (
                                        <div key={el.id} class="ai_bot_topCard allchat_box_item" onClick={() => navigate(`/ai-bots/bot-history/${el.bot_id}/${el.sesson_id}`)}>
                                            <div class="aiBot_topCard_container">
                                                <img class="ai-botCard_img" width="64" height="64" src={el.image} alt="" />
                                                <div class="ai_botCard_content">
                                                    <h2>New chat</h2>
                                                    <h3>{el.title}</h3>
                                                    <p class="ai-bot-card_pera">{el.description}</p>
                                                </div>
                                            </div>
                                            <small>
                                                {DateTimeDisplay(el.updatedAt)}
                                            </small>
                                        </div>
                                    )
                                })
                            }




                        </div>
                    ) : (
                        <PreLoader />
                    )
                }


                <Footer />

            </div>
            {
                state.botPop && (
                    <div class="all_chat_poup" onTouchStart={()=>setState({...state,botPop:true})} style={{ display: 'block' }}>
                        <div class="all_chatPoup_edit all_chatPoup_edit-show">
                            <div class="all_chatPoup_box_close" onClick={closePopUp}>
                                <i class="fa-solid fa-xmark"></i>
                            </div>
                            <h2 class="explore_bots_text">Explore bots</h2>
                            <div class="ai_list_nav">
                                {/* <button class="ai_nav_button active">Inspiration</button> */}
                                {
                                    categories && categories.map((el, i) => (
                                        <button key={el.id} className={!active && i === 0 ? "ai_nav_button active" : active === i ? "ai_nav_button active" : "ai_nav_button"} onClick={() => getBots(el.id, i)}>{el.tag_name}</button>

                                    ))
                                }
                            </div>
                            <div class="ai_chat_Search_header allChat_poup">
                                <form>
                                    <label class="ai_search-form">
                                        <input type="text" placeholder="Search chats" name='key' onChange={inputsHandler} value={inputField.key} />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none">
                                            <path fill="#565B66" d="M6.667 3.167a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-5.334 4a5.333 5.333 0 1 1 9.547 3.27l3.591 3.592a.667.667 0 0 1-.942.943L9.937 11.38a5.333 5.333 0 0 1-8.604-4.213Z">
                                            </path>
                                        </svg>
                                    </label>
                                </form>
                            </div>

                            {
                                bots && bots.map((el) => (

                                    <div key={el.id} className="ai_bot_topCard ai_botbody_card" >
                                        <Link to={`/new-chat/${el.id}`} style={{ color: 'black' }}>
                                            <div className="aiBot_topCard_container">
                                                <img className="ai-botCard_img" width="64" height="64" src={el.image} alt="" />
                                                <div className="ai_botCard_content">
                                                    <h2>{el.title}</h2>
                                                    <p className="ai-bot-card_pera">{el.description}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}
