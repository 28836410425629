import { useState, useEffect } from "react";
import images from "../../Assets/Images";
import axios from "axios";
import ChatLoader from "../loader/ChatLoader";


export default function OpenChatGpt({ openPlayParam, taskData,endOfListRef }) {
  const [messages, setMessages] = useState([]);
  const [state, setState] = useState({
    info: true,
    bot_response: false,
    task_complete: false,

  });
  const [input, setInput] = useState('');
  // const data = openPlayParam();
  // console.log(data)
  const botInfo = () => {
    setState({ ...state, info: true })
    // console.log(taskData)

  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    setInput('');
    if (!input.trim()) return;
    const userMessage = { role: 'user', content: input };
    setMessages([...messages, userMessage]);
    setState({ ...state, bot_response: true })
    axios.post(`https://chatbottest.successpilot.ai/api/genprompts`, { user_input: input, prompt: taskData.description }).then(async (response) => {
      const botMessage = { role: 'bot', content: response.data.ai_return };
      // console.log(response.data)

      setState({ ...state, bot_response: false, task_complete: true })
      setMessages([...messages, userMessage, botMessage]);
    }).catch((error) => {
      // console.error('Error:', error.response);
    })

  };
  const continueContentRead = () => {
    setTimeout(() => {
      endOfListRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
    openPlayParam((prevData) => ({ ...prevData, chatOpen: false, task_skip: false, task: false, task_complete: true }))
  }
  const backToContentRead = ()=>{
    setTimeout(() => {
      endOfListRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
    openPlayParam((prevData) => ({ ...prevData, chatOpen: false }))
  }
  return (
    <div className="ai_boats">
      <div className="header all_chatheader" style={{ maxWidth: '100%' }}>
        <i className="fa fa-angle-left" aria-hidden="true" onClick={backToContentRead} ></i>
        <h2>ChatGPT-4</h2>
        <svg onClick={botInfo} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
          <path fill="#565B66" d="M16 8a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM6 16c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10S6 21.523 6 16Z">
          </path>
          <path fill="#565B66" d="M16 18a1 1 0 0 1-1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 1-1 1ZM14.5 20.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z">
          </path>
          <path fill="#565B66" d="M16.39 11.811c-.957-.045-1.76.49-1.904 1.353a1 1 0 1 1-1.972-.328c.356-2.136 2.303-3.102 3.971-3.022.855.04 1.733.347 2.409.979C19.587 11.44 20 12.368 20 13.5c0 1.291-.508 2.249-1.383 2.832-.803.535-1.788.668-2.617.668a1 1 0 1 1 0-2c.67 0 1.186-.117 1.508-.332.25-.167.492-.46.492-1.168 0-.618-.212-1.003-.472-1.246-.277-.259-.68-.42-1.138-.443Z">
          </path>
        </svg>

      </div>

      <div className="container_large all_chat_list_container" style={{ maxWidth: '100%' }}>
        {state.info && (
          <div class="task_tomorrow_poup">
            <div class="task_tomorrow_poup-row">

              <div class="task_tomorrow_textBox">
                <h3>Task</h3>
                <p>{taskData.description}</p>
              </div>
              <div class="poup_close_box">
                <i class="fa-solid fa-xmark" onClick={() => setState({ ...state, info: false })}></i>
              </div>
            </div>

          </div>
        )

        }

        <div className="chatBox">
          {messages && messages.map((msg, index) => (
            <>
              <div key={index} className={msg.role === 'user' ? 'ai_boot_message' : 'ai_boot_message ans_maessage'}>
                <img src={msg.role === 'user' ? images['ai_chat.svg'] : images['ai_chat_2.svg']} alt="chat_logo" />
                <div className="chat_content">
                  {

                    typeof msg.content === 'string' ? (
                      <p>
                        {msg.content}
                      </p>
                    ) : (
                      <a href={msg.content[0]} target="_blank">{msg.content[1]}</a>
                    )
                  }

                </div>
              </div>
            </>
          ))}
          {
            state.bot_response === true && (
              <div className='ai_boot_message ans_maessage'>
                <img src={images['ai_chat_2.svg']} alt="chat_logo" />
                <div className="chat_content">
                  <ChatLoader />
                </div>
              </div>
            )
          }
        </div>

      </div>

      {
        state.task_complete ? (
          <div class="great-box-main" style={{ borderTop: '2px solid #19aa32' }}>
            <div class="p-container">
              <div>
                <div class="great-box">
                  <div class="great-img"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><rect width="19" height="19" x=".5" y=".5" fill="#19AA32" rx="9.5"></rect><rect width="19" height="19" x=".5" y=".5" stroke="#19AA32" rx="9.5"></rect><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.666 6.5 8.25 12.917 5.333 10"></path></svg></div>
                  <div class="great-text">
                    <p>Great job!</p>
                  </div>
                </div>

              </div>
              <div class="great-btn-div">
                <button class="great-btn"
                  style={{ background: '#19aa32', borderColor: '#19aa32' }}
                  onClick={continueContentRead}

                >
                  Continue
                  {/* Try Again */}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="chat_mass_container" style={{ maxWidth: '100%', width: '100%' }}>
            <form>
              <label htmlFor="message" className="chat_search_box">
                {
                  state.bot_response === true ? (

                    <div class="circle_loader"></div>
                  ) : (

                    <img onClick={handleSubmit} src={images['arrow.png']} style={input !== '' ? { position: 'absolute', display: 'block' } : {}} alt="" />
                  )
                }
                <textarea type="text" id="message" value={input} disabled={state.bot_response === true ? true : false} placeholder="Write a message..."
                  onChange={(e) => setInput(e.target.value)} ></textarea>

              </label>
            </form>
          </div>
        )
      }





    </div>
  )
}
