import { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate, useSearchParams, matchPath } from "react-router-dom";
import Guide from '../component/Guide';
import Unit from '../component/Unit';
import LessonContent from '../component/LessonContent';
import Profile from '../component/Profile';
import LessonComplete from '../component/LessonComplete';
import Feedback from '../component/Feedback';
import Settings from '../component/Settings';
import SubscriptionPlan from '../component/SubscriptionPlan';
import Reports from '../component/Reports';
import Language from '../component/Language';
import AiBots from '../component/bot/AiBots';
import Auth from "../helpers/Auth";
import BotChat from '../component/bot/BotChat';
import Challenges from '../component/challenges/Challenges';
import ChallengesTask from '../component/challenges/ChallengesTask';
import ChallengesContent from '../component/challenges/ChallengesContent';
import ChallengesComplete from '../component/challenges/ChallengesComplete';
import ChallengesJoin from '../component/challenges/ChallengesJoin';
import ChatBotHistory from '../component/bot/ChatBotHistory';
import ChatBotHistoryDetails from '../component/bot/ChatBotHistoryDetails';
import parse from "html-react-parser";
import PageNotFound404 from '../component/PageNotFound404';


export default function AuthUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout, http } = Auth();

  // console.log('auth',location); 

  useEffect(() => {
    // console.log(user === null, user === '');
    if (user) {
      if (user.isplanActive === undefined || user.isplanActive === false) {
        // console.log(user)
        const patterns = [
          "/",
          "/guides/:guide_id",
          "/guides/:guide_id/:unit_id/:lesson_id",
          "/guides/:guide_id/:unit_id/:lesson_id/complete",
          "/guides/:guide_id/:unit_id/:lesson_id/survey",
          "/ai-bots",
          "/new-chat/:bot_id",
          "/ai-bots/bot-history",
          "/ai-bots/bot-history/:bot_id/:session_id",
          "/challenges",
          "/challenges/:challenge_id",
          "/challenges/:challenge_id/tasks",
          "/challenges/:challenge_id/:daily_challenge_id",
          "/challenges/:challenge_id/:daily_challenge_id/complete"
        ]

        const match = patterns.reduce(
          (match, pattern) => (match ? match : matchPath(pattern, location.pathname)),
          null
        );
        // console.log(patterns)
        // navigate(location.pathname);
        if (match !== null) {

          navigate("/profile/subscription-plan");
        }

      }
      // const res =await http.get(`/pages/analytics/1`);
      //     console.log(res.data.data.social_url)
      //     const result = res.data.data;
      //     let head = result.page_meta_description +
      //       result.page_meta_keyword +
      //       result.social_page_title +
      //       result.social_page_description +
      //       result.social_url +
      //       result.social_preview_path +
      //       result.header_include;
      //     const body_start = result.body_start_include;  
      //     const body_end = result.body_end_include;
      //     const footer_include = result.footer_include;
      //     console.log(parse(head))
      //     document.getElementsByTagName('head')[0].appendChild(parse(` <meta name="description" content="Free Web tutorialsvggg">`));
      //   //   .then((res) => {
      //   // }).catch((err) => {
      //   //   console.log(err)
      //   // })



    } else {
      logout();
    }


    //when user login and access guest route then redirect to navigate('/')
    const guest_routes = ["/auth/signin", "/auth/register", "/auth/forgot", "/email-login", "/reset-password"];
    if (guest_routes.includes(location.pathname)) {
      navigate("/");
    }


  }, [location.pathname]);

  const VerifyEmail = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let email = searchParams.get("em");
    let token = searchParams.get("t");
    const { http } = Auth();
    // setParamToken(email);
    // console.log(email, token)
    if (email && token) {
      http
        .post("/user/verification", {
          email: email,
          verifyToken: token,
        }).then((res) => {
          if (res.data.status === 1) {
            navigate("/auth/signin");
          }
          // console.log(res.data)
        }).catch((err) => {
          console.log(err.response)
        })
    } else {

      navigate("/auth/signin");
    }

  }
  const EmailLogin = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let email = searchParams.get("em");
    const { http, setToken, user } = Auth();
    // setParamToken(email);
    // console.log(email)
    if (email) {
      http
        .post("/user/login/email", {
          email: email,

        }).then((res) => {
          // console.log(res.data)
          
          setToken(
            {
              id: res.data.data.id,
              name: res.data.data.name,
              email: res.data.data.email,
              isplanActive: res.data.data.isplanActive,
              accesChatGPT: res.data.data.accesChatGPT
            },
            res.data.data.accessToken
          );
        }).catch((err) => {
          console.log(err.response)
        })
    } else {
      navigate("/auth/signin");
    }
  }
  return (

    <>
      <Routes>
        {
          user && user.isplanActive === true ? (
            <>
              <Route index path="/" element={<Guide />} />
              <Route path="/guides/:guide_id" element={<Unit />}></Route>
              <Route path="/guides/:guide_id/:unit_id/:lesson_id" element={<LessonContent />} />
              <Route path="/guides/:guide_id/:unit_id/:lesson_id/complete" element={<LessonComplete />} />
              <Route path="/guides/:guide_id/:unit_id/:lesson_id/survey" element={<Feedback />} />

              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/subscription-plan" element={<SubscriptionPlan />} />
              <Route path="/profile/settings" element={<Settings />} />
              {/* <Route path="/profile/reports" element={<Reports />} /> */}
              <Route path="/profile/language" element={<Language />} />


              <Route path="/ai-bots" element={<AiBots />} />
              <Route path="/new-chat/:bot_id" element={<BotChat />} />
              <Route path="/ai-bots/bot-history" element={<ChatBotHistory />} />
              <Route path="/ai-bots/bot-history/:bot_id/:session_id" element={<ChatBotHistoryDetails />} />

              <Route path="/challenges" element={<Challenges />} />
              <Route path="/challenges/:challenge_id" element={<ChallengesJoin />} />
              <Route path="/challenges/:challenge_id/tasks" element={<ChallengesTask />} />
              <Route path="/challenges/:challenge_id/:daily_challenge_id" element={<ChallengesContent />} />
              <Route path="/challenges/:challenge_id/:daily_challenge_id/complete" element={<ChallengesComplete />} />

              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/email-login" element={<EmailLogin />} />
              <Route path="/*" element={<PageNotFound404/>} />
            </>
          ) : (
            <>
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/subscription-plan" element={<SubscriptionPlan />} />
              <Route path="/profile/settings" element={<Settings />} />
              {/* <Route path="/profile/reports" element={<Reports />} /> */}
              <Route path="/profile/language" element={<Language />} />

              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/email-login" element={<EmailLogin />} />
              <Route path="/*" element={<PageNotFound404/>} />

            </>
          )
        }

      </Routes>
    </>

  )
}
