import React from 'react';
import images from "../Assets/Images";
import { Link, useLocation, useNavigate,useParams } from "react-router-dom";


export default function LessonComplete() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const closeLesson = () => {
        // console.log(location)
        navigate(`/guides/${params.guide_id}`, { state: location.state })
    }
    // console.log(location)
    return (
        <div className='step_5'>
        <div className='container_large'>
      
        <div class="complete_lessson_box">
            <button class="page_close" onClick={closeLesson} ><i class="fa fa-close"></i></button>
            <div>
                <img src={images["complete_lesson.svg"]} alt="" />
            </div>
            <h4>Lesson complete!</h4>
            <p>Keep up the practice to maintain your progress!</p>
            <Link to={`/guides/${params.guide_id}/${params.unit_id}/${params.lesson_id}/survey`} state={location.state
            } class="btn start_lesson btn_fix">Continue</Link>
        </div>
        </div>
        </div>
    )
}
