import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import images from '../Assets/Images';

export default function Language() {
    const navigate = useNavigate();
    return (
        <div className="container" style={{ padding: '0px' }}>
            <div className="settings_section">
                <div className="container_large  padding-0">
                    <div className="profile_header">
                        <Link onClick={(e) => {
                            e.preventDefault();
                            navigate("/profile/settings");
                        }} >
                            <div><i className="fa fa-angle-left"></i></div>
                        </Link>
                        <p>Language</p>
                        <div></div>
                    </div>
                </div>
                <div className="profile_body">

                    <div class="lang_box_container">

                        <div class="language_box check_active">
                            <div class="lang_imageContentBox">
                                <img src={images['usa.svg']} alt="usa" />
                                <h5>English</h5>
                            </div>
                            <div class="lang_arrowBox">
                                <i class="fa-solid fa-check"></i>
                            </div>
                        </div>
                        {/* <div class="language_box ">
                        <div class="lang_imageContentBox">
                            <img src={images['spanish.svg']} alt="usa"/>
                                <h5>
                                    Español</h5>
                        </div>
                        <div class="lang_arrowBox">
                            <i class="fa-solid fa-check"></i>
                        </div>
                    </div> */}

                    </div>

                </div>
            </div>
        </div>
    )
}
