import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from '../Logo';
import Footer from '../Footer';
import Auth from "../../helpers/Auth";
import PreLoader from '../PreLoader';
import Utils from "../../helpers/Utils";

export default function AiBots() {
    const [categories, setCategories] = useState([]);
    const [bots, setBots] = useState([]);
    const [active, setActive] = useState(0);
    const [lastChat, setLastChat] = useState();
    const { http, user } = Auth();
    const {DateTimeDisplay} = Utils();
    const navigate = useNavigate();
    useEffect(() => {
        http.get('/list-bot-categories').then((res) => {
            setCategories(res.data.data);
            // console.log(res.data)
        }).catch((err) => {
            console.log(err.response)
        })

        http.get(`/get-lastchat-by-user/${user.id}`).then((res) => {
            // console.log(res.data.data)
            setLastChat(res.data.data[0]);
        }).catch((err) => {
            console.log(err.response)
        })
        getBots(1);
    }, []);

    const getBots = (id, i) => {
        setActive(i);
        http.get(`/bots-list-categroy-wise/${id}`).then((res) => {
            setBots(res.data.data);
            // console.log(res.data)
        }).catch((err) => {
            console.log(err.response)
        })
    }
    return (
        <>
            {

                categories.length > 0 ? (
                    <>
                        <Logo />
                        <div className="ai_boats">


                            <div className="container_large">

                                {
                                    lastChat && (
                                        <>
                                            <div className="aiChat_header">
                                                <h1 className="aiChat_title">Continue your last chat</h1>
                                                <Link to={'/ai-bots/bot-history'} clLinkssName="ai_chat_all">View
                                                    all</Link>
                                            </div>

                                            <div className="ai_bot_topCard" onClick={() => navigate(`/ai-bots/bot-history/${lastChat.bot_id}/${lastChat.sesson_id}`)}>
                                                <div className="aiBot_topCard_container">
                                                    <img className="ai-botCard_img" width="64" height="64" src={lastChat.image} alt="" />
                                                    <div className="ai_botCard_content">
                                                        <h2>{lastChat.label ? lastChat.label:'New chat'}</h2>
                                                        <h3>{lastChat.title}</h3>
                                                        <p className="ai-bot-card_pera">{lastChat.description}</p>
                                                    </div>
                                                </div>
                                                <small>{DateTimeDisplay(lastChat.updatedAt)}</small>
                                            </div>
                                        </>
                                    )
                                }


                                {/* <!-- ai_bot_list_box --> */}

                                <div className="ai_bot_list_container containerBottom_padding">
                                    <h2>Explore bots</h2>

                                    <div className="ai_list_nav">
                                        {
                                            categories.map((el, i) => (
                                                <button key={el.id} tt={active} className={!active && i === 0 ? "ai_nav_button active" : active === i ? "ai_nav_button active" : "ai_nav_button"} onClick={() => getBots(el.id, i)}>{el.tag_name}</button>

                                            ))
                                        }

                                    </div>


                                    <div style={{paddingBottom:'23px'}}> 
                                    {
                                        bots && bots.map((el) => (
                                            <div key={el.id} className="ai_bot_topCard ai_botbody_card">
                                                <Link to={`/new-chat/${el.id}`} style={{ color: 'black' }}>
                                                    <div className="aiBot_topCard_container">
                                                        <img className="ai-botCard_img" width="64" height="64" src={el.image} alt="" />
                                                        <div className="ai_botCard_content">
                                                            <h2>{el.title}</h2>
                                                            <p className="ai-bot-card_pera">{el.description}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    }
</div>   


                                </div>



                            </div>
                        </div>
                        <Footer />
                    </>
                ) : (
                    <PreLoader />
                )

            }

        </>
    )
}
