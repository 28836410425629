import React, { useState, useEffect } from 'react';

export default function Quiz({ quiz, pageType, step_count, setContinueBtn }) {
    const [checkQuestion, setCheckQuestion] = useState(null);
    const [hint, setHint] = useState(false);
    const [selectOption, setSelectOption] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [quizContent, setQuizContent] = useState();

    //step_count use for genrate unique id 
    useEffect(() => {
        let content = JSON.parse(JSON.parse(quiz))[0];
        setQuizContent(content);
    }, [])





    const selectAnswer = (e, is_correct) => {
        /**
         * after submit answer then not select able input fileld quiz...
         */
        if (!submit) {
            setSelectOption(true);
            setCheckQuestion(is_correct);
            return true;
        }
        return false;
    }
    const submitAnswer = () => {
        setContinueBtn(true);
        setSubmit(true);
    }
    return (
        <>
            {
                quizContent && (
                    <div className="gray_box">
                        <h4 className="qn_hding">{quizContent.text}</h4>
                        <ul className="mcq_answers_ul after_check_choose_option">
                            {
                                quizContent.options.map((op, i) => {
                                    return (
                                        <li key={step_count + i} className="limcq_answers_li" >
                                            <label htmlFor={'id_' + step_count + '_' + i} onClick={(e) => selectAnswer(e, op.is_correct)}>
                                                <input type="radio" name="answer" id={'id_' + step_count + '_' + i} />
                                                <span className="mcq_option__checkmark_blank_nrm_check" style={{ display: submit ? 'none' : '' }} ></span>

                                                {
                                                    submit && op.is_correct === true && checkQuestion === true ? (
                                                        <svg className="quiz-checkmark-correct" xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
                                                            <rect width="19" height="19" x=".5" y="1" fill="currentColor" rx="9.5"></rect>
                                                            <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.667 7 8.25 13.417 5.333 10.5"></path>
                                                        </svg>
                                                    ) : submit && op.is_correct === true && checkQuestion === false ? (
                                                        <svg class="quiz-checkmark-disabled" xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
                                                            <rect width="19" height="19" x=".5" y="1" fill="currentColor" rx="9.5"></rect>
                                                            <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.667 7 8.25 13.417 5.333 10.5"></path>
                                                        </svg>
                                                    ) : submit && op.is_correct === false ? (
                                                        <svg class="quiz-checkmark-incorrect" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
                                                            <rect width="19" height="19" x=".5" y=".5" fill="currentColor" rx="9.5"></rect>
                                                            <path fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M6.088 6.088a.583.583 0 0 1 .824 0L10 9.175l3.088-3.087a.583.583 0 0 1 .825.824L10.825 10l3.088 3.088a.583.583 0 0 1-.825.825L10 10.825l-3.088 3.088a.583.583 0 1 1-.824-.825L9.175 10 6.088 6.912a.583.583 0 0 1 0-.824Z">
                                                            </path>
                                                        </svg>
                                                    ) : ''

                                                }

                                                {/* <svg className="quiz-checkmark-correct" xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
                    <rect width="19" height="19" x=".5" y="1" fill="currentColor" rx="9.5"></rect>
                    <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.667 7 8.25 13.417 5.333 10.5"></path>
                  </svg> */}
                                                <span className="mcq_answers_text">{op.text}</span>
                                            </label>

                                        </li>
                                    )
                                })
                            }

                        </ul>
                        {
                            hint && <div className="mcq_hint_content">{quizContent.hint}</div>
                        }

                        {
                            submit && checkQuestion === true && (
                                <div className="correct_congo">
                                    <div className="correct_congo_hd">
                                        <svg className="quiz-checkmark-correct" xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
                                            <rect width="19" height="19" x=".5" y="1" fill="currentColor" rx="9.5"></rect>
                                            <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.667 7 8.25 13.417 5.333 10.5"></path>
                                        </svg>
                                        <h5 className="correct_congo__title">Correct answer</h5>

                                    </div>
                                    <p className="correct_congo__text">{quizContent.correct_text}</p>
                                </div>
                            )
                        }

                        {
                            submit && checkQuestion === false && (
                                <div className="incorrect_congo">
                                    <div className="incorrect_congo_hd">
                                        <svg className="quiz-checkmark-incorrect" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
                                            <rect width="19" height="19" x=".5" y=".5" fill="currentColor" rx="9.5"></rect>
                                            <path fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M6.088 6.088a.583.583 0 0 1 .824 0L10 9.175l3.088-3.087a.583.583 0 0 1 .825.824L10.825 10l3.088 3.088a.583.583 0 0 1-.825.825L10 10.825l-3.088 3.088a.583.583 0 1 1-.824-.825L9.175 10 6.088 6.912a.583.583 0 0 1 0-.824Z">
                                            </path>
                                        </svg>
                                        <h5 className="incorrect_congo__title">Incorrect answer</h5>


                                    </div>
                                    <p className="incorrect_congo__text">{quizContent.wrong_text}</p>
                                </div>
                            )
                        }



                        {
                            !submit && (
                                <div className="hint_submit_btn_wrap">
                                    {
                                        quizContent.hint ? (<button type="button" className="guide-lesson-quiz__action guide-lesson-quiz__hint-button" style={{ cursor: 'pointer' }} onClick={() => setHint(!hint)}>Hint</button>) : (
                                            <button type="button" style={{ opacity: 0 }} disabled={true}></button>
                                        )
                                    }

                                    {/* <button type="button" className="guide-lesson-quiz__action guide-lesson-quiz__submit-button">Submit</button> */}
                                    <button type="button" disabled={selectOption ? false : true} className={selectOption ? 'guide-lesson-quiz__action guide-lesson-quiz__submit-button' : 'guide-lesson-quiz__action guide-lesson-quiz__hint-button'} onClick={submitAnswer}>Submit</button>
                                </div>
                            )
                        }


                    </div>
                )
            }

        </>

    )
}
