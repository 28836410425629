import React, { useState } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Chunk from './content/Chunk';
import Task from './content/Task';
import Quiz from './content/Quiz';


export default function LessonIntro({ setLessonState }) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const params = useParams();
    const [continueBtn, setContinueBtn] = useState(true);
    const [openPlay, setOpenPlay] = useState(
        {
            chatOpen: false,
            data: '',
            task_skip: false,
            task: false,
            task_complete: false
        }
    );
    let intro_info = '';
    const closeIntro = () => {
        // console.log(state);
        if (setLessonState.Challenges) {
            navigate(`/challenges/${params.challenge_id}/tasks`)
        } else {

            navigate(`/guides/${params.guide_id}`, { state: state });
        }

    }

    if (setLessonState.Challenges) {
        intro_info = setLessonState.intro.challenge_page_contents[0];
    } else {

        intro_info = setLessonState.intro.coursiv_lesson_page_contents[0];
    }



    const startLesson = () => {
        setLessonState.setState(false);
    }



    return (
        <div className="step_3">
            <div className="container_large">
                <div className="start_lesson_header">
                    <div className="closeLesson_iconBox" onClick={closeIntro}>
                        <i className="fa fa-close"></i>
                    </div>
                    <div className="lessson_progress_bar">
                        <div className="lessson_progress_line" style={{ width: "100" }}></div>
                    </div>


                </div>

                <div className="start_lesson_contentBox">
                    {
                        intro_info.page_type === 'chunk' && (
                            <Chunk chunk={intro_info.content} pageType="intro" />
                        )
                    }
                    {
                        intro_info.page_type === 'task' && (
                            <Task task={intro_info.content} pageType="step" openPlayParam={setOpenPlay} setContinueBtn={setContinueBtn} openPlayParamData={openPlay} />
                        )
                    }
                    {
                        intro_info.page_type === 'quiz' && (
                            <Quiz quiz={intro_info.content} pageType="intro" step_count={1} setContinueBtn={setContinueBtn} />
                        )
                    }

                </div>
                {/* <div className="start_lesson_contentBox">
                        <h2>{setLessonState.intro.name}</h2>
                        <p>{setLessonState.intro.lesson_intro}</p>
                        <img src={setLessonState.intro.images} alt="start_lesson" />
                    </div> */}

                <button className="btn start_lesson btn_fix" onClick={startLesson}>Start lesson</button>

            </div>
        </div>
    )


}
