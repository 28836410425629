import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Chunk from '../content/Chunk';
import Quiz from '../content/Quiz';
import Task from '../content/Task';
import LessonIntro from '../LessonIntro';
import PreLoader from '../PreLoader';
import Auth from '../../helpers/Auth';
import Challenges from './Challenges';
import OpenChatGpt from '../content/OpenChatGpt';
export default function ChallengesContent() {
    const [state, setState] = useState(true);
    const [count, setCount] = useState(0);
    const [progress_bar, setProgress] = useState(5);
    const { http, user, errorHandle } = Auth();
    const location = useLocation();
    const navigate = useNavigate();
    const [totalStep, setTotalStep] = useState(0);
    const [continueBtn, setContinueBtn] = useState(true);
    const [openPlay, setOpenPlay] = useState(
        {
          chatOpen: false,
          data: '',
          task_skip: false,
          task: false,
          task_complete:false
        }
      );

    const endOfListRef = useRef(null);
    const [stepData, setStepData] = useState([]);

    const [lessonContent, setLessonContent] = useState([]);
    const [intro, setIntro] = useState([]);
    const params = useParams();

    useEffect(() => {

        http
            .get(`/daily-challenges-content/${params.daily_challenge_id}`)
            .then((res) => {
                // console.log(res.data);
                if (res.data.data.length > 0) {
                    res.data.data.forEach(element => {
                        // console.log(element.page_type_name);
                        if (element.page_type_name === "Intro") {
                            setIntro(element);
                        }
                        if (element.page_type_name === "Step") {
                            if (element.challenge_page_contents[0].page_type === 'quiz') {
                                setContinueBtn(false)
                            }
                            if (element.challenge_page_contents[0].page_type === 'task') {
                                setOpenPlay({ ...openPlay, task: true })
                              }
                            setLessonContent(element.challenge_page_contents);
                            setTotalStep(element.challenge_page_contents.length)
                            setStepData([element.challenge_page_contents[0]]);
                            setProgress(100 / element.challenge_page_contents.length);
                            // console.log(element, element.challenge_page_contents[0])
                        }

                    });
                }

            })
            .catch((err) => {
                errorHandle(err);
                // console.log(err.response, err.message);
            });
    }, []);

    const nextContent = async () => {
        // Scroll to the bottom after a brief delay to ensure the DOM is updated
        // console.log(count, totalStep)
        // totalStep-1 because first index show default
    
        if (totalStep - 1 > count) {
            // console.log(count)
            setTimeout(() => {
                endOfListRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 100);
            // Finish lesson
            if (lessonContent[count + 1].page_type === 'quiz') {
                setContinueBtn(false)
            }
            if (openPlay.task) {
                // console.log('ff',openPlay)
                setOpenPlay({ ...openPlay, task_skip: true,task:false,task_complete:false })
                // console.log('f2',openPlay)
              }
        
              if (lessonContent[count + 1].page_type === 'task') {
                setOpenPlay({ ...openPlay, task: true,task_complete:false  })
              }
        
            setStepData((prevData) => [
                ...prevData,
                lessonContent[count + 1]])

            setProgress((prevData) => prevData + (100 / totalStep));

        } else {
            // location.state.prevPath = location.pathname;
            if (location.state && location.state.day) {
               
                try {
                    let result  = await http.post(`/user-complete-challenges`, {
                        user_id: user.id,
                        challenge_id: params.challenge_id,
                        daily_challenge_id: params.daily_challenge_id,
                        current_challenge_day:location.state.day,
                    }); 
                } catch (err) {
                    console.log(err.response)
                }
            
            }



            navigate(
                `/challenges/${params.challenge_id}/${params.daily_challenge_id}/complete`,
                { state: location.state }
            );
        }
        setCount(count + 1);

    };

    const closeLesson = () => {
        // console.log(location);
        navigate(`/challenges/${params.challenge_id}/tasks`);
    };

    return (
        <>
            {lessonContent.length > 0 || intro.length > 0 ? (
                <>
                    {state ? (
                        <LessonIntro
                            setLessonState={{
                                setState: setState,
                                setCount: setCount,
                                intro: intro,
                                Challenges: true
                            }}
                        />
                    ):openPlay.chatOpen ? <OpenChatGpt openPlayParam={setOpenPlay} taskData={openPlay.data} endOfListRef={endOfListRef} /> : (
                        <div className="step_3">
                            <div className="container_large">
                                <div className="start_lesson_header">
                                    <div className="closeLesson_iconBox" onClick={closeLesson}>
                                        <i className="fa fa-close"></i>
                                    </div>
                                    <div className="lessson_progress_bar">
                                        <div
                                            className="lessson_progress_line"
                                            style={{ width: progress_bar + "%" }}
                                        ></div>
                                    </div>
                                </div>
                                <div className="start_lesson_contentBox" id="lesson_content">
                                    {stepData.length > 0 && stepData.map((item, index) => (

                                        <div key={item.id}>
                                            {

                                                item.page_type === 'chunk' ? (
                                                    <Chunk chunk={item.content} pageType="step" />
                                                ) : item.page_type === 'quiz' ? (
                                                    <Quiz quiz={item.content} pageType="step" step_count={item.id} setContinueBtn={setContinueBtn} />
                                                ) : item.page_type === 'task' ? (
                                                    <Task task={item.content} pageType="step" openPlayParam={setOpenPlay} setContinueBtn={setContinueBtn} openPlayParamData={openPlay} />

                                                ) : ''
                                            }
                                        </div>
                                    ))}
                                    <div ref={endOfListRef}></div>
                                </div>
                                {
                  continueBtn && (
                    <>
                      {
                        openPlay.task ? (<button
                          className="btn start_lesson btn_fix"
                          onClick={nextContent}
                          style={{ backgroundColor: '#f5f7f9', color: '#40444d', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          <span>Skip practice</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#40444D" d="M9.293 18.707a1 1 0 0 1 0-1.414L14.586 12 9.293 6.707a1 1 0 0 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0Z"></path></svg>
                        </button>) : (
                          <button
                            className="btn start_lesson btn_fix"
                            onClick={nextContent}

                          >
                            {totalStep - 1 > count ? 'Continue' : 'Finish lesson'}
                          </button>
                        )

                      }
                    </>
                  )
                }

                            </div>
                        </div>
                    )}
                </>
            ) : (
                <PreLoader />
            )}
        </>
    );
}
