import React, { useState, useEffect } from 'react';
import images from "../Assets/Images";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export default function Feedback() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const closeLesson = () => {
        // console.log(location)
        navigate(`/guides/${params.guide_id}`, { state: location.state })
    }
    const [rating, setActive] = useState(0);
    const active_feedback = {
        borderColor: "#5653fe",
        backgroundColor: " #eeeeff"
    }
    useEffect(() => {
        let feedback = document.querySelectorAll('.feedback_option');
        feedback.forEach(el => {
            el.addEventListener('click', function () {

                setActive(parseInt(this.getAttribute('rating')))
            })
        });

    }, [rating]);
    return (
        <div className='step_6'>
            <div className='container_large'>
                <div className="last_step">
                    <button className="page_close" onClick={closeLesson}><i className="fa fa-close"></i></button>
                    <div>
                        <img src={images["last_step.svg"]} alt="" />
                    </div>
                    <h4>How engaging did you find the lesson content?</h4>
                    <ul className="emoji_list" id='feedback'>
                        <li><button type='button' rating="1" className='feedback_option' style={rating === 1 ? active_feedback : {}}><img src={images["emoji_1.png"]} alt="" /></button></li>
                        <li><button type='button' rating="2" className='feedback_option' style={rating === 2 ? active_feedback : {}}><img src={images["emoji_2.png"]} alt="" /></button></li>
                        <li><button type='button' rating="3" className='feedback_option' style={rating === 3 ? active_feedback : {}}><img src={images["emoji_3.png"]} alt="" /></button></li>
                        <li><button type='button' rating="4" className='feedback_option' style={rating === 4 ? active_feedback : {}}><img src={images["emoji_4.png"]} alt="" /></button></li>
                        <li><button type='button' rating="5" className='feedback_option' style={rating === 5 ? active_feedback : {}}><img src={images["emoji_5.png"]} alt="" /></button></li>
                    </ul>
                    <div className="last_step_subtext">
                        <p>Not engaging at all</p>
                        <p>Extremely engaging</p>
                    </div>
                    {/* {
                        rating > 0 && rating < 4 && (
                            <div style={{padding:'1.5rem'}}>
                                <h3 class="feedback_title">What could've been done to improve your experience?</h3>
                                <textarea maxlength="150" class="feedback_textarea" placeholder="Tell us what you like, or what you didn’t..."></textarea>
                                <h6 class="feedback_comment_counter">150/150</h6>
                            </div>
                        )

                    } */}
                    {/* {
                        rating >= 4 && (
                            <div style={{padding:'1.5rem'}}>
                                <a role="button" href="javascript:void(0)" class="guide-survey-page__trustpilot" target="_blank" rel="noopener noreferrer">
                                    <strong>Review us on</strong>
                                    <img width="24" height="24" src={images['rating.svg']} role="presentation" alt="" />
                                    <span>Successpilot</span>
                                </a>
                            </div>
                        )
                    } */}
                    <div style={{ marginTop: "56px" }}>

                        <button className="btn start_lesson" onClick={closeLesson}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
