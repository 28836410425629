import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Auth from "../../helpers/Auth";
import axios from 'axios';
import images from "../../Assets/Images";
import ChatLoader from "../loader/ChatLoader";
import Loader from "../loader/Loader1";



export default function ChatBotHistoryDetails() {
    const navigate = useNavigate();
    const params = useParams();
    const [bot, setBots] = useState();
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [messagesFirst, setMessagesFirst] = useState();
    const { http, user } = Auth();
    const [state, setState] = useState({
        bot_response: false,
        del_sess_pop: false,
        pop_info: false,
        sesson_delete: false,
        editable: false
    });
    const [inputField, setInputField] = useState({
        label: bot && bot.label ? bot.label : 'New Chat',

    });
    useEffect(() => {
        http.get(`/get-bot-history-by-id/${params.session_id}`).then((res) => {
            console.log(res.data);
            setBots(res.data.data[0]);
        }).catch((err) => {
            console.log(err.response)
        });

        axios.post(`https://chatbottest.successpilot.ai/api/chat`, {
            bot_id: params.bot_id,
            prompt: '',
            show_history: 'yes',
            user_id: user.id,
            session_id: params.session_id

        }).then((response) => {
            // const botMessage = { role: 'bot', content: response.data.response };
            // console.log(response.data)
            // setMessagesFirst(response.data.response);
            if (params.bot_id === 17) {

                setMessages(JSON.parse(response.data));
            } else {
                setMessages(response.data);
            }
        }).catch((error) => {
            console.error('Error:', error.response);
        })



    }, [])
    const handleSubmit = async (e) => {
        e.preventDefault();
        setInput('');
        if (!input.trim()) return;
        const userMessage = { role: 'user', content: input };
        setMessages([...messages, userMessage]);
        setState({ ...state, bot_response: true })
        axios.post(`https://chatbottest.successpilot.ai/api/chat`, {
            bot_id: params.bot_id,
            prompt: input,
            show_history: 'no',
            user_id: user.id,
            session_id: params.session_id

        }).then(async (response) => {
            const botMessage = { role: 'bot', content: response.data.response };
            let result = await http.post(`/create-update-bot-history`, {
                sesson_id: response.data.session_id,
                bot_id: params.bot_id,
                user_id: user.id,
                description: typeof response.data.response === 'string' ? response.data.response.substring(0, 110) : JSON.stringify(response.data.response),
            });
            // console.log(result);
            setState({ ...state, bot_response: false, session_id: response.data.session_id })
            setMessages([...messages, userMessage, botMessage]);
        }).catch((error) => {
            console.error('Error:', error.response);
        })

    };
    const botInfo = () => {
        setState({ ...state, pop_info: true })
        console.log(messages)
    }
    const deleteSessionBtnPopUp = () => {

        setState({ ...state, pop_info: false, del_sess_pop: true })

    }
    const deleteSession = async () => {


        setState({ ...state, sesson_delete: true })
        try {
            let removeFromDb = await http.post(`/delete-bot-history/${params.session_id}`);
            let result = await axios.post(`https://chatbottest.successpilot.ai/api/delete-session`, {
                session_id: params.session_id

            });
            setState({ ...state, del_sess_pop: false, sesson_delete: false })
        } catch (err) {
            console.log(err.response)
        }

    }
    const updateLabel = () => {
        setState({ ...state, editable: false })
        http.post(`/create-update-bot-history`, {
            sesson_id: params.session_id,
            label: inputField.label

        }).then((res) => {
            setBots({ ...bot, label: inputField.label })
            // console.log(res.data)
        }).catch((err) => {
            console.log(err.response);
        });
        console.log(inputField.label)
    }
    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    }

    return (
        <div className="ai_boats">
            <div className="header all_chatheader">
                <i className="fa fa-angle-left" aria-hidden="true" onClick={() => navigate('/ai-bots')}></i>
                <h2>{bot && bot.label ? bot.label : 'New Chat'}</h2>

                <svg onClick={botInfo} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
                    <path fill="#565B66" d="M16 8a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM6 16c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10S6 21.523 6 16Z">
                    </path>
                    <path fill="#565B66" d="M16 18a1 1 0 0 1-1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 1-1 1ZM14.5 20.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z">
                    </path>
                    <path fill="#565B66" d="M16.39 11.811c-.957-.045-1.76.49-1.904 1.353a1 1 0 1 1-1.972-.328c.356-2.136 2.303-3.102 3.971-3.022.855.04 1.733.347 2.409.979C19.587 11.44 20 12.368 20 13.5c0 1.291-.508 2.249-1.383 2.832-.803.535-1.788.668-2.617.668a1 1 0 1 1 0-2c.67 0 1.186-.117 1.508-.332.25-.167.492-.46.492-1.168 0-.618-.212-1.003-.472-1.246-.277-.259-.68-.42-1.138-.443Z">
                    </path>
                </svg>

            </div>



            <div className="container_large all_chat_list_container">

                {
                    bot && (
                        <div className="chat_info_cotainer">
                            <div className="chat_info_header">
                                <img className="chat-info-img" width="64" height="64" src={bot.image} role="presentation" alt="..." />
                                <div className="chat_info_content">
                                    <h4>{bot.title}</h4>
                                    {
                                        bot.bot_tags && (
                                            <>
                                                <span className="chat_tag">WRITING</span>
                                                <span className="chat_tag">CHATGPT</span>
                                            </>
                                        )
                                    }

                                </div>
                            </div>
                            <p className="chat_info_pera">{bot.description}</p>
                            <div className="chatgpt_infoBtn_container">
                                <button className="chatgpt_infoBtn" onClick={botInfo}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                                        <path fill="#24234C" d="M8 2.667a5.333 5.333 0 1 0 0 10.666A5.333 5.333 0 0 0 8 2.667ZM1.333 8a6.667 6.667 0 1 1 13.334 0A6.667 6.667 0 0 1 1.333 8Z">
                                        </path>
                                        <path fill="#24234C" d="M8 6.667c.368 0 .667.298.667.666v4a.667.667 0 1 1-1.334 0v-4c0-.368.299-.666.667-.666ZM9 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z">
                                        </path>
                                    </svg>
                                    Bot info
                                </button>
                                <button className="chatgpt_infoBtn" onClick={deleteSessionBtnPopUp}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                                        <path fill="#24234C" d="M4.667 2.667c0-.737.597-1.334 1.333-1.334h4c.736 0 1.333.597 1.333 1.334V4h1.327a.64.64 0 0 1 .012 0H14a.667.667 0 1 1 0 1.333h-.713l-.578 8.095a1.333 1.333 0 0 1-1.33 1.239H4.621c-.7 0-1.28-.541-1.33-1.239l-.578-8.095H2A.667.667 0 1 1 2 4h2.667V2.667ZM6 4h4V2.667H6V4ZM4.05 5.333l.57 8h6.76l.57-8h-7.9Zm2.617 1.334c.368 0 .666.298.666.666v4a.667.667 0 0 1-1.333 0v-4c0-.368.298-.666.667-.666Zm2.666 0c.369 0 .667.298.667.666v4a.667.667 0 0 1-1.333 0v-4c0-.368.298-.666.666-.666Z">
                                        </path>
                                    </svg>
                                    Delete session
                                </button>
                            </div>
                        </div>
                    )
                }


                <div className="chatBox">

                    {messages && messages.map((msg, index) => (
                        <>
                            {
                                msg.role !== 'system' && (
                                    <div key={index} className={msg.role === 'user' ? 'ai_boot_message' : 'ai_boot_message ans_maessage'}>
                                        <img src={msg.role === 'user' ? images['ai_chat.svg'] : images['ai_chat_2.svg']} alt="chat_logo" />
                                        <div className="chat_content">

                                            {
                                                typeof msg.content === 'string' ? (
                                                    <p>
                                                        {msg.content}
                                                    </p>
                                                ) : (
                                                    <a href={msg.content[0]} target="_blank">{msg.content[1]}</a>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }

                        </>
                    ))}
                    {
                        state.bot_response === true && (
                            <div className='ai_boot_message ans_maessage'>
                                <img src={images['ai_chat_2.svg']} alt="chat_logo" />
                                <div className="chat_content">
                                    <ChatLoader />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>




            <div className="chat_mass_container">
                <form>
                    <label htmlFor="message" className="chat_search_box">
                        {
                            state.bot_response === true ? (

                                <div class="circle_loader"></div>
                            ) : (

                                <img onClick={handleSubmit} src={images['arrow.png']} style={input !== '' ? { position: 'absolute', display: 'block' } : {}} alt="" />
                            )
                        }
                        <textarea type="text" id="message" value={input} disabled={state.bot_response === true ? true : false} placeholder="Write a message..."
                            onChange={(e) => setInput(e.target.value)} ></textarea>

                    </label>
                </form>
            </div>

            {state.del_sess_pop && (
                <div class="session_delete_poup" style={{ display: state.del_sess_pop ? 'block' : 'none' }}>
                    <div class="session_delete_edit" style={{ bottom: 0 }}>

                        {/* <div class="delete_session_close" onClick={() => setState({ ...state, del_sess_pop: false })} style={{ cursor: 'pointer' }}>
                            <i class="fa-solid fa-xmark"></i>
                        </div> */}

                        <h2 class="delete_session_heading">Do you want to delete this session?</h2>
                        <p class="delete_session_pera">Are you sure you want to permanently delete this session?</p>

                        <div class="delete_sessionbtn_box">
                            <button onClick={() => setState({ ...state, del_sess_pop: false })} style={{ cursor: 'pointer' }} disabled={state.sesson_delete}>Cancel</button>
                            <button class="delete_session_btn" onClick={deleteSession} style={{ cursor: 'pointer' }} disabled={state.sesson_delete}>Delete</button>
                        </div>
                    </div>

                </div>
            )

            }
            {
                state.pop_info && (

                    <div class="chat_info_poup">
                        <div class="new_chatPoup_edit">
                            <div class="chat_info_poup_show" >
                                <svg onClick={() => setState({ ...state, editable: true })} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="#5653FE" d="M13.577 1.91a.833.833 0 0 1 1.179 0l3.333 3.334a.833.833 0 0 1 0 1.179L7.256 17.256a.833.833 0 0 1-.59.244H3.334a.833.833 0 0 1-.833-.833v-3.334c0-.22.088-.433.244-.589l8.333-8.333 2.5-2.5Zm-1.91 4.269-7.5 7.5v2.154H6.32l7.5-7.5-2.154-2.154ZM15 7.155l1.322-1.322-2.155-2.154L12.845 5 15 7.155Z"></path></svg>
                                <i class="fa-solid fa-xmark" style={{ marginLeft: '17px' }} onClick={() => setState({ ...state, pop_info: false })}></i>
                            </div>
                            <div class="chat_info_header">
                                <img class="chat-info-img" width="64" height="64"
                                    src="https://d2ykpjr9108fpy.cloudfront.net/ai-bots-images/chat-gpt-classic.png"
                                    role="presentation" alt="" />
                                <div class="chat_info_content">

                                    {
                                        state.editable ? (
                                            <form onSubmit={updateLabel}>
                                                <input type="text" name="label" onChange={inputsHandler} value={inputField.label} />
                                                <input type="submit" style={{ opacity: 0 }} />
                                            </form>
                                        ) : (
                                            <h4>{bot && bot.label ? bot.label : 'New Chat'}</h4>
                                        )
                                    }
                                    <span className="chat_info_pera">ChatGPT Classic</span>
                                    {
                                        bot && bot.bot_tags && (
                                            <>
                                                <span className="chat_tag">WRITING</span>
                                                <span className="chat_tag">CHATGPT</span>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <p class="chat_info_pera">The latest version of GPT-4 with no additional capabilities</p>
                            <div class="chatgpt_infoBtn_container">
                                <button class="chatgpt_infoBtn active" onClick={()=>navigate(`/new-chat/${params.bot_id}`)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                                        <path fill="#fff"
                                            d="M8 2.667c.368 0 .667.298.667.666v4h4a.667.667 0 1 1 0 1.334h-4v4a.667.667 0 1 1-1.334 0v-4h-4a.667.667 0 0 1 0-1.334h4v-4c0-.368.299-.666.667-.666Z">
                                        </path>
                                    </svg>
                                    New chat
                                </button>
                                <button class="chatgpt_infoBtn" onClick={deleteSessionBtnPopUp} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                                        <path fill="#24234C"
                                            d="M4.667 2.667c0-.737.597-1.334 1.333-1.334h4c.736 0 1.333.597 1.333 1.334V4h1.327a.64.64 0 0 1 .012 0H14a.667.667 0 1 1 0 1.333h-.713l-.578 8.095a1.333 1.333 0 0 1-1.33 1.239H4.621c-.7 0-1.28-.541-1.33-1.239l-.578-8.095H2A.667.667 0 1 1 2 4h2.667V2.667ZM6 4h4V2.667H6V4ZM4.05 5.333l.57 8h6.76l.57-8h-7.9Zm2.617 1.334c.368 0 .666.298.666.666v4a.667.667 0 0 1-1.333 0v-4c0-.368.298-.666.667-.666Zm2.666 0c.369 0 .667.298.667.666v4a.667.667 0 0 1-1.333 0v-4c0-.368.298-.666.666-.666Z">
                                        </path>
                                    </svg>
                                    Delete session
                                </button>
                            </div>
                        </div>


                    </div>

                )
            }
            {
                state.sesson_delete && (
                    <Loader />
                )
            }
        </div>
    )
}
