import React, { useState, useEffect } from "react";
import Auth from "../helpers/Auth";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Logo from "./Logo";
import PreLoader from "./PreLoader";

export default function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  let [token, setParamToken] = useState();
  const { http, setToken } = Auth();
  const [inputField, setInputField] = useState({
    name: "",
    email: "",
    password: "",
    cnf_password: "",
  });
  const [errors, setErrors] = useState({});
  const [passwordView, setPasswordView] = useState(true);
  const [cnfPasswordView, setCnfPasswordView] = useState(true);
  const [register, setRegister] = useState(true);
  const [terms, setTerms] = useState({
    isCheck: false,
    error: false,
  });

  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  const setTermsCondition = (e) => {
    setTerms({ ...terms, isCheck: e.target.checked });
    // console.log(e.target.checked)
  };

  function userRegister(e) {
    // api call
    e.preventDefault();
    const newErrors = validateForm(inputField);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Form submission logic here
      // console.log(inputField);
      if (terms.isCheck) {
        setTerms({ ...terms, isCheck: false, error: false });
        setRegister(false);
        http
          .post("/user-register", {
            name: inputField.name,
            email: inputField.email,
            password: inputField.password,
            userToken: token,
          })
          .then((res) => {
            // console.log(res.data);
            if (res.data.status) {
              // console.log(res.data);
              // navigate(`/email-login?em=${res.data.data.email}`);
              http
                .post("/user/login/email", {
                  email: inputField.email,
                })
                .then((res) => {
                  // console.log(res.data);
                  setToken(
                    {
                      id: res.data.data.id,
                      name: res.data.data.name,
                      email: res.data.data.email,
                      isplanActive: res.data.data.isplanActive,
                      accesChatGPT: res.data.data.accesChatGPT,
                    },
                    res.data.data.accessToken
                  );
                });
            }
          })
          .catch((err) => {
            setRegister(true);
            console.log(err.response);
            errors.email = err.response.data.message;
            setErrors(errors);
          });
      } else {
        setTerms({ ...terms, error: true });
      }
    } else {
      console.log(`Form submission failed due to validation errors.`);
    }
  }

  const validateForm = (data) => {
    const errors = {};
    let label = "";

    if (!data.name.trim()) {
      errors.name = "Username is required";
    }

    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }

    if (!data.password) {
      errors.password = "Password is required";
    } else if (data.password.length < 8) {
      errors.password = `Password must be 8 characters.`;
    } else if (!/[!@#$%^&*?_~]{1,}/.test(data.password)) {
      //min 1 special characters
      // console.log(/[!@#$%^&*?_~]{1,}/.test(data.password))
      label = "Special character.";
      errors.password = `Password must be 8 characters with at least one ${label}  `;
    } else if (!/[a-z]{1,}/.test(data.password)) {
      //min 1 a-z
      label = "Small character.";
      errors.password = `Password must be 8 characters with at least one ${label}  `;
    } else if (!/[A-Z]{1,}/.test(data.password)) {
      //min 1 A-Z
      label = "Alphabet character.";
      errors.password = `Password must be 8 characters with at least one ${label}  `;
    } else if (!/[0-9]{1,}/.test(data.password)) {
      //min 1 0-9
      label = "Numeric character.";
      errors.password = `Password must be 8 characters with at least one ${label}  `;
    }

    if (data.cnf_password !== data.password) {
      errors.cnf_password = "Passwords do not match";
    }

    return errors;
  };

  const viewPassword = () => {
    if (passwordView) {
      setPasswordView(false);
    } else {
      setPasswordView(true);
    }
  };

  const viewPasswordCnf = () => {
    if (cnfPasswordView) {
      setCnfPasswordView(false);
    } else {
      setCnfPasswordView(true);
    }
  };

  useEffect(() => {
    let token = searchParams.get("token");
    setParamToken(token);
    if (token !== null) {
      http
        .post("/user-by-token", {
          userToken: token,
        })
        .then((res) => {
          // console.log(res.data, res.data.data.name);
          setInputField({
            ...inputField,
            name: res.data.data.name,
            email: res.data.data.email,
          });
        })
        .catch((err) => {
          console.log(err.response);
          // setInputField({ ...inputField, name: "abc", email: "addbc@gmaill.com" })
        });
    }
    // console.log(location, searchParams.get("a"));
  }, []);

  return (
    <div className="container">
      {register ? (
        <div className="create_account">
          <div className="create_account_container">
            <div className="create_account_header">
              <Logo />
            </div>

            <div className="accounts_status_nav">
              <ul>
                <li>Purchase plan</li>
                <li className="active">Create Account</li>
                <li>Sign up offer</li>
                <li>Onboarding</li>
              </ul>
            </div>
            <div className="progress_mark_nav">
              <ul>
                <li className="complete_mark line_active">
                  <i className="fa-solid fa-check"></i>
                </li>
                <li className="progress_active">
                  <i className="fa-solid fa-check"></i>
                </li>
                <li>
                  <i className="fa-solid fa-check"></i>
                </li>
                <li>
                  <i className="fa-solid fa-check"></i>
                </li>
              </ul>
            </div>

            <div className="create_account_fromBox">
              <h3>Create your account</h3>
              <p>To save yor progress and access the plan</p>
              <form onSubmit={userRegister}>
                <div className="input_box">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    placeholder="example@gmail.com"
                    id="email"
                    name="email"
                    onChange={inputsHandler}
                    value={inputField.email}
                  />
                  {errors.email && (
                    <p className="error_text">{"*" + errors.email}</p>
                  )}
                  {/* <p className="error_text">* Please enter a valid email</p> */}
                </div>

                <div className="input_box">
                  <label htmlFor="create_name">Username</label>
                  <input
                    type="text"
                    placeholder="Create a Username"
                    id="name"
                    name="name"
                    onChange={inputsHandler}
                    value={inputField.name}
                  />
                  {errors.name && (
                    <p className="error_text">{"*" + errors.name}</p>
                  )}
                </div>
                <div className="input_box">
                  <label htmlFor="password">Password</label>
                  <input
                    type={passwordView ? "password" : "text"}
                    placeholder="Password"
                    id="password"
                    name="password"
                    onChange={inputsHandler}
                    value={inputField.password}
                  />
                  {errors.password && (
                    <p className="error_text">{"*" + errors.password}</p>
                  )}
                  <i
                    className={
                      passwordView ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"
                    }
                    onClick={viewPassword}
                  ></i>
                </div>
                <div className="input_box">
                  <label htmlFor="confrim_password">Confirm Password</label>
                  <input
                    type={cnfPasswordView ? "password" : "text"}
                    placeholder="Confirm Password"
                    id="confrim_password"
                    name="cnf_password"
                    onChange={inputsHandler}
                    value={inputField.cnf_password}
                  />
                  {errors.cnf_password && (
                    <p className="error_text">{"*" + errors.cnf_password}</p>
                  )}
                  <i
                    className={
                      cnfPasswordView
                        ? "fa-solid fa-eye"
                        : "fa-solid fa-eye-slash"
                    }
                    onClick={viewPasswordCnf}
                  ></i>
                </div>
                <div className="check_box ">
                  <input
                    type="checkbox"
                    id="agree_create_account"
                    name="create_account"
                    onClick={setTermsCondition}
                  />
                  <label htmlFor="agree_create_account">
                    I agree with{" "}
                    <a
                      href="https://successpilot.ai/terms.html"
                      target="_blank"
                    >
                      Terms
                    </a>
                    ,
                    <a
                      href="https://successpilot.ai/privacy.html"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </label>

                  {terms.error && (
                    <p
                      className="error_text"
                      style={{
                        color: "rgb(230, 40, 40)",
                        fontSize: "13px",
                        margin: "5px",
                      }}
                    >
                      * Please agree terms
                    </p>
                  )}
                </div>
                <input
                  type="submit"
                  value="Create Account"
                  className="btn Sign_inbtn" //btn_disable
                />
              </form>
            </div>
            <p
              className="login_subtext"
              style={{
                marginTop: "0px",
                lineHeight: "0px",
                marginBottom: "13px",
              }}
            >
              Already have an account? <Link to={"/auth/signin"}>Signin</Link>
            </p>
          </div>
        </div>
      ) : (
        <PreLoader />
      )}
    </div>
  );
}
