import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Auth from "../helpers/Auth";
import PreLoader from "./PreLoader";
import Footer from "./Footer";
import Logo from "./Logo";

export default function Guide() {
  const activeLesson = "0_0"; //change dynamic later
  const location = useLocation();
  const [guides, setGuide] = useState([]);
  const [currentLesson, setCurrentLesson] = useState();
  const [percentage, setPercentage] = useState();
  const navigate = useNavigate();
  const { http, logout,errorHandle } = Auth();
  useEffect(() => {
    //Runs on every render
    http
      .get("/guide/home")
      .then((res) => {
        // console.log(res.data);
        // setGuide(res.data.data);
        setGuide(res.data.data.guideList);
        setCurrentLesson(res.data.data.continewLessonData);
      })
      .catch((err) => {
        errorHandle(err);
      });
  }, []);
  // console.log(guides)
  return (
    <>
      {guides.length > 0 ? (
        <>
          <Logo />
          <div className="container">
            <div className="guides_box">
              <h2>Guides</h2>
              <p>Step-by-step guides to mastery</p>
              <div className="ongoing_guide">
                <img src={currentLesson.images} alt="guide" />
                <ul className="ongoing_list">
                  <li>{currentLesson.unit} Unit</li>
                  <li>
                    <span></span>{currentLesson.hours} Hours
                  </li>
                </ul>
                <h3>{currentLesson.name}</h3>
                <p>{currentLesson.description}</p>
                <div className="progress_bar">
                  <div className="progress_line" style={{ width: currentLesson.percentage+'%' }}></div>
                </div>
                <Link to={"guides/" + currentLesson.id} state={{
                  activeUnit: currentLesson.unit_id,
                }} className="btn" style={{ display: "inline-block" }}>Continue learning</Link>
              </div>
            </div>
            <div className="recommended_box">
              <div className="heading_box">
                <h3>Recommended for you</h3>
                <p>Based on your results</p>
              </div>
              <div className="recommended_container">
                {guides?.map(function (item, i) {
                  return (
                    <Link
                      key={i}
                      to={"guides/" + item.id}
                      state={{
                        slug: activeLesson,
                        guides: item.name,
                        guide_id: item.id,
                        guide_images: item.images,
                        guide_desc: item.description,
                        guide_unit: item.unit,
                        prevLoc: location.pathname,
                      }}
                      className="recommended_item"
                    >
                      <img src={item.images} alt="recommended-1" />
                      <h5>{item.name}</h5>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <PreLoader />
      )}
    </>
  );
}
